import { Injectable } from '@angular/core';
import { differenceInHours } from 'date-fns';

@Injectable({
    providedIn: 'root',
})
export class DateService {
    // Calcula la diferencia en horas entre dos fechas
    getDifferenceInHours(date1: Date, date2: Date): number {
        return differenceInHours(date1, date2);
    }

    // Verifica si han pasado 24 horas desde una fecha dada
    isDatePassed24Hours(date: Date): boolean {
        return this.getDifferenceInHours(new Date(), date) >= 24;
    }

    isDatePassed48Hours(date: Date): boolean {
        return this.getDifferenceInHours(new Date(), date) >= 48;
    }
}
