import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class VisionApiService {
    constructor(private apiService: ApiService) {}

    async getVision(imageUrl: string): Promise<any> {
        return await this.apiService.postRequest('vision/vision', { imageUrl });
    }
}
