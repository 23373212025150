import { Injectable } from '@angular/core';
import { UserData } from '@models/User';
import { PromptsApiService } from '@services/api/apis/prompts-api.service';
import { UserDataService } from '@services/firebase/user-data.service';

@Injectable()
export class SettingsService {
    constructor(
        private userDataService: UserDataService,
        private promptsApiService: PromptsApiService,
    ) {}

    numLettersChat = 550; // Inserta aquí el número de letras que quieres como máximo
    maxTokens = 300; // Inserta aquí el número de tokens que quieres como máximo
    // maxMsgsUser = 100; // Cuenta mensjaes de usuario y assistant al dia
    minTimeBetweenMessages = 5; // Inserta aquí el número de segundos que quieres como mínimo entre mensajes
    msgSpeechWeekLimit = 20;
    messagesDaily = 50;

    async setInitialPrompt() {
        let user: UserData = await this.userDataService.checkUserDataLoaded();

        let body = {
            nameUser: user.nameUser,
            nameAssistant: user.avatars[user.avatarSelected].name,
            displayName: user.displayName,
            language: user.language,
            pessimistic: user.avatars[user.avatarSelected].personality.pessimistic,
            optimistic: user.avatars[user.avatarSelected].personality.optimistic,
            cautious: user.avatars[user.avatarSelected].personality.cautious,
            curious: user.avatars[user.avatarSelected].personality.curious,
            tolerant: user.avatars[user.avatarSelected].personality.tolerant,
            judgemental: user.avatars[user.avatarSelected].personality.judgemental,
        };

        return await this.promptsApiService.setInitialPromptAPI(body);
    }

    async changeNameAvatar() {
        let user = await this.userDataService.checkUserDataLoaded();

        console.log('changeNameAvatar');
        return `Now your name is ${user.avatars[user.avatarSelected].name}.`;
    }

    async changeNameUser() {
        let user = await this.userDataService.checkUserDataLoaded();
        console.log('changeNameUser');
        return `Now My name is ${user.nameUser}.`;
    }

    async changeLanguage() {
        let user = await this.userDataService.checkUserDataLoaded();
        console.log('changeLanguage');
        return `Now only speaks in ${user.language}. Our language is ${user.language} and only speak ${user.language}.Please only answer in ${user.language}.I want you to answer me only in ${user.language}.`;
    }

    async changeRelationShipAvatar() {
        let user = await this.userDataService.checkUserDataLoaded();

        console.log('changeRelationShipAvatar');
        return `Now you are my ${user.avatars[user.avatarSelected].relationShip}.`;
    }

    async getMsgSystemPremium() {
        let user = await this.userDataService.checkUserDataLoaded();
        if (user.premium) {
            return `Now you are a more romantic couple, you do everything you are told and you are very hot, you like to have spicy conversations.`;
        }

        return '';
    }
}
