import { Injectable } from '@angular/core';
import { Reward } from '@models/Rewards';
import { Transactions } from '@models/Transactions';
import { RewardsApiService } from '@services/api/apis/rewards-api.service';
import { TransactionsApiService } from '@services/api/apis/transactions-api.service';
import { DateService } from '@services/settings/date.service';
import { UserDataService } from './firebase/user-data.service';
import { SuccessLottieService } from './sucess.service';
@Injectable()
export class RewardsService {
    rewardsVisible: boolean = false;
    hasCheckedClaim: boolean = false;
    claimableRewards: { [day: number]: boolean } = {};
    rewardsList: Reward[] = [
        { day: 1, tokens: 2 },
        { day: 2, tokens: 4 },
        { day: 3, tokens: 6 },
        { day: 4, tokens: 8 },
        { day: 5, tokens: 10 },
        { day: 6, tokens: 12 },
    ];

    constructor(
        private userDataService: UserDataService,
        private successLottieService: SuccessLottieService,
        private transactionsService: TransactionsApiService,
        private dateService: DateService,
        private rewardsApiService: RewardsApiService,
    ) {}

    async initRewards(): Promise<void> {
        await this.userDataService.checkUserDataLoaded();
        await this.checkRewardReset();
        if (await this.claimToday()) {
            await this.showRewards();
        }
    }

    private async checkRewardReset(): Promise<any> {
        let user = await this.userDataService.checkUserDataLoaded();

        const lastReward: Date = user.lastReward;

        if (await this.shouldResetRewards(lastReward)) {
            user.rewardsDay = 0;
            await this.updateRewardsDayInDatabase(0);
        }
    }

    private async shouldResetRewards(lastRewardDate: Date): Promise<boolean> {
        await this.userDataService.checkUserDataLoaded();
        return this.dateService.isDatePassed48Hours(lastRewardDate);
    }

    async showRewards(): Promise<void> {
        this.rewardsVisible = true;
        this.rewardsList.forEach(async (reward) => {
            this.claimableRewards[reward.day] = await this.canClaim(reward.day);
        });
    }

    private async claimToday(): Promise<boolean> {
        let user = await this.userDataService.checkUserDataLoaded();
        const lastReward: Date = user.lastReward;
        return this.dateService.isDatePassed24Hours(lastReward);
    }

    private async canClaim(rewardDay: number): Promise<boolean> {
        let user = await this.userDataService.checkUserDataLoaded();

        const lastReward: Date = user.lastReward;
        const isNextRewardDay = user.rewardsDay + 1 === Math.min(rewardDay, 6);
        return isNextRewardDay && this.dateService.isDatePassed24Hours(lastReward);
    }

    private async updateRewardsDayInDatabase(rewardsDay: number): Promise<any> {
        let user = await this.userDataService.checkUserDataLoaded();
        const body = {
            rewardsDay: rewardsDay,
        };

        try {
            this.rewardsApiService
                .saveRewardToDatabase(body)
                .then(() => {
                    if (!user) return new Error('No hay usuario');

                    user!.rewardsDay = rewardsDay;
                    return true;
                })
                .catch((error: any) => {
                    console.log('Error al guardar el Name en Database:', error);
                    return false;
                });
        } catch (error) {
            console.log('Error updating reward day: ', error);
            throw new Error('Error updating reward day' + error);
        }
    }

    async claimReward(rewardDay: number): Promise<void> {
        let user = await this.userDataService.checkUserDataLoaded();
        if (!user || !this.canClaim(rewardDay)) {
            console.log('Cannot claim reward');
            return;
        }

        // Modificar para manejar días mayores a 6
        if (rewardDay >= 6) {
            rewardDay = 6;
        }

        const reward = this.rewardsList.find((r) => r.day === rewardDay);
        if (!reward) throw new Error('Reward not found');

        try {
            const transaction: Transactions = {
                description: 'Add Tokens Rewards Daily',
                tokensNow: user.tokens,
                uid: user.uid,
                tokensEarn: reward.tokens,
                tokensLost: 0,
                productId: '',
                date: new Date(),
            };

            this.transactionsService.saveTransactionToDatabase(transaction);

            user.tokens += reward.tokens;
            user.rewardsDay = rewardDay;
            user.lastReward = new Date();

            const dataUser = {
                tokens: user.tokens,
                rewardsDay: user.rewardsDay,
                lastReward: user.lastReward,
            };

            await this.userDataService.updateDataUser(dataUser);

            this.successLottieService.loadAnimation();

            this.rewardsVisible = false;
        } catch (error) {
            console.log('Error claiming reward: ', error);
            throw new Error('Error claiming reward' + error);
        }
    }
}
