import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const routeTransitionAnimations = trigger('routeAnimations', [
    transition('* <=> *', [
        query(':enter, :leave', style({ width: '100%', height: '100%' }), { optional: true }),
        group([
            query(':leave', [animate('300ms ease', style({ opacity: 0, transform: 'translateX(-50%)' }))], { optional: true }),
            query(':enter', [style({ opacity: 0, transform: 'translateX(50%)' }), animate('300ms ease', style({ opacity: 1, transform: 'translateX(0)' }))], { optional: true }),
        ]),
    ]),
]);
