import { Injectable } from '@angular/core';
import { AudioToTextAPIService } from '@services/api/apis/audio-to-text-api.service';

interface IAudioDataEvent {
    data: Blob;
}

@Injectable()
export class AudioToTextService {
    constructor(private audioToTextApiSv: AudioToTextAPIService) {}

    async sendAudioToOpenAI(audio: IAudioDataEvent): Promise<any> {
        const audioForm = new FormData();
        audioForm.append('audio', audio.data);

        try {
            const response = await this.audioToTextApiSv.sendAudioToTextRunpod(audioForm);
            return response;
        } catch (err) {
            console.log('Error sending audio:', err);
            throw err;
        }
    }
}
