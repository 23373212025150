import { Injectable } from '@angular/core';
import { AiMessage } from '@models/AiMessage';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class RabbitmqApiService {
    constructor(private apiService: ApiService) {}

    getTaskApi(taskId: string) {
        return this.apiService.getRequest<AiMessage>(`rabbitmq/task/${taskId}`);
    }
}
