import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class ChatLlmApiService {
    constructor(private apiService: ApiService) {}

    async addChatLlmTaskApi(body: any) {
        return await this.apiService.postRequest<any>('chat_llm/addChatLlmTask', body);
    }
}
