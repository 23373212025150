import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class LanguagesApiService {
    constructor(private apiService: ApiService) {}

    async changeLanguageUserAppApi(languageApp: string) {
        return this.apiService.postRequest('chats/prompt/initialPrompt', { languageApp });
    }
}
