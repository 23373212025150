import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class StorageApiService {
    constructor(private apiService: ApiService) {}

    async addStorageApi(bufferImg: Blob, pathFile: string): Promise<any> {
        const arrayBuffer = await bufferImg.arrayBuffer();
        const body = { bufferImg: Array.from(new Uint8Array(arrayBuffer)), pathFile };
        return await this.apiService.postRequest('storage/storage/add', body);
    }
}
