import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';
@Injectable()
export class PremiumApiService {
    constructor(private apiService: ApiService) {}

    // Get premium history
    async getPremiumLastHistory(): Promise<any> {
        let test = await this.apiService.getRequest<any>('purchases/premium/purchases');
        console.log('test', test);
        return test;
    }

    // Add premium purchase
    async addPremiumPurchaseHistory(purchase: any) {
        return await this.apiService.postRequest('purchases/premium/purchase', purchase);
    }

    // Check if user has premium
    async checkUserHasPremium(): Promise<boolean> {
        return await this.apiService.getRequest<boolean>('purchases/premium/check');
    }
}
