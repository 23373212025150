import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class PurchasesServiceApi {
    constructor(private apiService: ApiService) {}

    async savePurchaseApi(data: any): Promise<any> {
        return await this.apiService.postRequest('purchases/purchases/purchase', data);
    }
}
