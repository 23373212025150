import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class RefersApiService {
    constructor(private apiService: ApiService) {}

    // Send refer code
    async sendReferCodeApi(referFather: string) {
        return await this.apiService.postRequest<any>('users/refers/add-refer-father', { referFather });
    }
}
