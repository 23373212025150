import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class AudioToTextAPIService {
    constructor(private apiService: ApiService) {}

    sendAudioToTextRunpod(audio: FormData): Promise<any> {
        return this.apiService.postRequest<any>('audio_to_text/deepgram', audio);
    }
}
