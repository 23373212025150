import { Injectable } from '@angular/core';
import { MessageModel } from '@models/ChatHistory';
import { ChatHistoryService } from '@services/chat-history.service';
import { SettingsService } from '@services/settings/settings.service';
import { UserDataService } from './firebase/user-data.service';

@Injectable()
export class PersonalityService {
    constructor(
        private settingsService: SettingsService,
        private chatHistoryService: ChatHistoryService,
        private userDataService: UserDataService,
    ) {}

    async changeNameUser(newName: string) {
        let user = await this.userDataService.checkUserDataLoaded();

        const newData = {
            nameUser: newName,
        };

        await this.userDataService
            .updateDataUser(newData)
            .then(async () => {
                user!.nameUser = newName;
                await this.updateNameUserInChat();
                return true;
            })
            .catch((error: any) => {
                console.error('Error al guardar el Name en Database:', error);
                return false;
            });
    }

    async updateNameUserInChat() {
        let user = await this.userDataService.checkUserDataLoaded();
        await this.chatHistoryService.updateChatHistory('nameUser', user.nameUser);

        const chatHistory: MessageModel = {
            message: await this.settingsService.changeNameUser(),
            date: new Date(),
            role: 'system',
        };

        this.chatHistoryService.saveChatHistory(chatHistory);
    }

    async changeLanguage(newLanguage: string) {
        let user = await this.userDataService.checkUserDataLoaded();

        const newData = {
            language: newLanguage,
        };

        await this.userDataService
            .updateDataUser(newData)
            .then(async () => {
                user!.language = newLanguage;
                await this.chatHistoryService.updateChatHistory('language', newLanguage);
                await this.updateLanguageInChat();
                return true;
            })
            .catch((error: any) => {
                console.error('Error al guardar el Language en Database:', error);
                return false;
            });
    }

    async updateLanguageInChat() {
        const chatHistory: MessageModel = {
            message: await this.settingsService.changeLanguage(),
            date: new Date(),
            role: 'system',
        };

        this.chatHistoryService.saveChatHistory(chatHistory);
    }
}
