import { Injectable } from '@angular/core';
import { AiMessage } from '@models/AiMessage';
import { MessageModel } from '@models/ChatHistory';
import { UserData } from '@models/User';
import { ChatLlmApiService } from '@services/api/apis/chat-llm-api.service';
import { RabbitmqApiService } from '@services/api/apis/rabbitmq-api.service';
import { ChatHistoryService } from '@services/chat-history.service';
import { UserDataService } from '@services/firebase/user-data.service';
import { TextToSpeechService } from '@services/ia/text-to-speech.service';
import { ToolsService } from '@services/settings/tools.service';
import { Observable, firstValueFrom, switchMap, takeUntil, tap, timer } from 'rxjs';

const CHAT_HISTORY_KEY = 'chatHistory';

@Injectable()
export class ChatLLM {
    private isRequestPending = false;
    timeOfLastMessage = 0;
    secondBetweenMessages = 4000;
    typeMessage: any;

    constructor(
        private chatHistoryService: ChatHistoryService,
        private userDataService: UserDataService,
        private txt2SpeechService: TextToSpeechService,
        private textToSpeechService: TextToSpeechService,
        private toolsService: ToolsService,
        private chatllmApiService: ChatLlmApiService,
        private rabbitmqService: RabbitmqApiService,
    ) {}

    async sendChatGpt(typeMessage: string): Promise<any> {
        this.typeMessage = typeMessage;
        if (this.isRequestPending) {
            console.warn('Ya hay una petición en proceso. Por favor, espera a que se complete.');
            return;
        }

        this.isRequestPending = true;
        this.chatHistoryService.isLoadingChat = true;

        let body = await this.createMessageToSend();

        try {
            const response = await this.chatllmApiService.addChatLlmTaskApi(body);
            if (!response || !response.taskId) {
                throw new Error('Task ID not found in the response');
            }

            return await firstValueFrom(this.streamOutput(response.taskId));
        } catch (err) {
            console.log('Error: ', err);
            throw err;
        } finally {
            this.isRequestPending = false;
            this.chatHistoryService.isLoadingChat = false;
        }
    }

    streamOutput(taskId: string): Observable<any> {
        return new Observable((observer) => {
            const timeout = timer(45000); // Temporizador de 45 segundos

            const subscription = timer(0, 700)
                .pipe(
                    takeUntil(timeout),
                    switchMap(() => this.rabbitmqService.getTaskApi(taskId)),
                    tap({
                        next: (res) => {
                            if (res !== null && res !== undefined) {
                                this.handleResponse(observer, res);
                            } else {
                                console.error('Received null or undefined response:', res);
                                observer.error(new Error('Received null or undefined response'));
                            }
                        },
                        error: (err) => {
                            console.error('Error in tap operator:', err);
                            observer.error(err);
                        },
                        complete: () => observer.complete(),
                    }),
                )
                .subscribe();

            return () => subscription.unsubscribe();
        });
    }

    private handleResponse(observer: any, res: AiMessage) {
        if (res.status === 'completed') {
            if (this.typeMessage === 'chat') {
                this.handleAIChatResponse(res);
            } else if (this.typeMessage === 'call') {
                this.handleAICallResponse(res);
            }

            observer.next(res);
            observer.complete();
        } else if (res.status === 'failed') {
            console.log('Error: ', res.result.error);
            if (this.typeMessage === 'chat') {
                this.handleAIChatResponseError();
            } else if (this.typeMessage === 'call') {
                this.handleAICallResponseError();
            }
            observer.error(res.result.error);
        } else {
            console.log('Waiting for response...');
        }
    }

    private async handleAIChatResponse(res: any) {
        try {
            let user: UserData = await this.userDataService.checkUserDataLoaded();

            // Controla tiempo entre mensajes para que no se envíen muy rápido forzosamente
            const currentTime = Date.now();
            const timeElapsed = currentTime - this.timeOfLastMessage;
            if (!user?.premium && timeElapsed < this.secondBetweenMessages) {
                console.log('Esperando para enviar mensaje...');
                await new Promise((resolve) => setTimeout(resolve, this.secondBetweenMessages - timeElapsed));
            }

            console.log('Enviando mensaje...');

            const messageAI = this.filterResponseAI(res.result.data);
            console.log(messageAI, user);

            const speakingRate = user.avatars[user.avatarSelected].speech.speakingRate;
            const pitch = user.avatars[user.avatarSelected].speech.pitch;

            if (await this.txt2SpeechService.getAllowSpeechText()) this.textToSpeechService.playMessageAudio(messageAI, pitch, speakingRate, false);
            // Dividir el mensaje en sub-mensajes basados en saltos de línea
            const messages = messageAI.split('\n');

            for (let i = 0; i < messages.length; i++) {
                const msg = messages[i];
                if (msg.trim() !== '') {
                    // Asegurarse de que el mensaje no esté vacío
                    const formatToSave: MessageModel = {
                        message: msg,
                        date: new Date(),
                        role: 'assistant',
                        price: '0',
                    };

                    await this.userDataService.increaseDailyMessages();
                    this.chatHistoryService.saveChatHistory(formatToSave);
                    this.toolsService.soundNewMessage('ting');
                    this.chatHistoryService.scrollToBottom();

                    // Esperar 2 segundos antes de procesar el siguiente mensaje, excepto para el último
                    if (i < messages.length - 1) {
                        await new Promise((resolve) => setTimeout(resolve, 2000));
                    }
                }
            }
        } catch (error) {
            this.handleAIChatResponseError();
        }

        this.chatHistoryService.isLoadingChat = false;
        this.isRequestPending = false;

        return res;
    }

    private async handleAICallResponse(res: any) {
        try {
            console.log('Enviando mensaje...');

            const messageAI = this.filterResponseAI(res.result.data);
            await this.userDataService.checkUserDataLoaded();

            this.textToSpeechService.playMessageAudio(messageAI, 1, 1, true);

            // Dividir el mensaje en sub-mensajes basados en saltos de línea
            const messages = messageAI.split('\n');

            for (let i = 0; i < messages.length; i++) {
                const msg = messages[i];
                if (msg.trim() !== '') {
                    // Asegurarse de que el mensaje no esté vacío
                    const formatToSave: MessageModel = {
                        message: msg,
                        date: new Date(),
                        role: 'assistant',
                        price: '0',
                        hide: true,
                    };

                    this.chatHistoryService.saveChatHistory(formatToSave);

                    // Esperar 2 segundos antes de procesar el siguiente mensaje, excepto para el último
                    if (i < messages.length - 1) {
                        await new Promise((resolve) => setTimeout(resolve, 2000));
                    }
                }
            }
        } catch (error) {
            this.handleAICallResponseError();
        }

        this.isRequestPending = false;
        return res;
    }

    private async handleAIChatResponseError() {
        const messageAI = 'Error in the message, please resend it';

        const formatToSave: MessageModel = {
            message: messageAI,
            date: new Date(),
            role: 'assistant',
            price: '0',
        };

        this.chatHistoryService.saveChatHistory(formatToSave);
        this.toolsService.soundNewMessage('ting');
        this.chatHistoryService.scrollToBottom();
        this.chatHistoryService.isLoadingChat = false;
        this.isRequestPending = false;
    }

    private async handleAICallResponseError() {
        const messageAI = 'Error in the message, please resend it';

        const formatToSave: MessageModel = {
            message: messageAI,
            date: new Date(),
            role: 'assistant',
            price: '0',
            hide: true,
        };

        this.chatHistoryService.saveChatHistory(formatToSave);
        this.isRequestPending = false;
    }

    private async createMessageToSend() {
        let user: UserData = await this.userDataService.checkUserDataLoaded();

        let instructions = this.chatHistoryService.chatHistory;

        console.log('CHAT:\n\n\n', instructions);

        const body = {
            prompt: instructions,
            uid: user?.uid,
        };

        return body;
    }

    private filterResponseAI(messageAI: string): string {
        messageAI = this.cleanEdges(messageAI);

        return this.filterResponse(messageAI);
    }

    private cleanEdges(str: string): string {
        return str.replace(/^(?:[\s\/\\:"]+)+|(?:[\s\/\\:"]+)+$/g, '');
    }

    private filterResponse(messageAI: string): string {
        // Eliminate the pattern ### returned by the server if the response is incorrect
        const indexOfPattern = messageAI.indexOf('###');

        if (indexOfPattern !== -1) {
            // TODO: Importante: eliminar el patrón ### del servidor para que no se muestre en el chat pero eliminar desde la IA, no con esta funcion
            console.log('ALERT:Pattern ### found, it must be eliminated', messageAI);
            return messageAI.substring(0, indexOfPattern).trim();
        }

        return messageAI; // Return the original text if the pattern isn't found
    }

    get isRequestInProgress(): boolean {
        return this.isRequestPending;
    }
}
