import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { UserData } from '@models/User';
import { TranslateService } from '@ngx-translate/core';
import { StorageApiService } from '@services/api/apis/storage-api.service';
import { VisionApiService } from '@services/api/apis/vision-api.service';
import { ChatHistoryService } from '@services/chat-history.service';
import { UserDataService } from '@services/firebase/user-data.service';
import { ChatLLM } from '@services/ia/chat-llm.service';
import { LoadingService } from '@services/loading.service';
import { MessagesService } from '@services/messages.service';
import { TokensService } from '@services/purchases/tokens.service';
import { SweetAlertsService } from '@services/sweet-alerts.service';

@Injectable()
export class VisionService {
    constructor(
        public messageService: MessagesService,
        private userDataService: UserDataService,
        private sweetAlertsService: SweetAlertsService,
        private translate: TranslateService,
        private chatllmService: ChatLLM,
        private chatHistoryService: ChatHistoryService,
        private tokensService: TokensService,
        public loadingService: LoadingService,
        private visionApiService: VisionApiService,
        private storageApiService: StorageApiService,
    ) {}

    async takePhoto() {
        try {
            const user: UserData = await this.userDataService.checkUserDataLoaded();

            const permissionsStatus = await this.checkCameraAndStoragePermission();
            if (permissionsStatus.camera !== 'granted' || permissionsStatus.photos !== 'granted') {
                await this.requestCameraAndStoragePermission();
            }

            if (!user?.premium) {
                this.sweetAlertsService.show('info', 'Oops...', this.translate.instant('MESSAGES.NEED_PREMIUM'), 'dark');
                return;
            }

            if (this.chatllmService.isRequestInProgress) {
                console.warn('Ya hay una petición en proceso. Por favor, espera a que se complete.');
                return;
            }

            if (!this.chatHistoryService.checkMessageLimit()) return;

            const image = await Camera.getPhoto({
                quality: 90,
                allowEditing: true,
                resultType: CameraResultType.Uri,
                source: CameraSource.Prompt, // Allows the user to choose between camera or gallery
            });

            if (!image.webPath) {
                throw new Error('No se pudo obtener la ruta de la imagen.');
            }

            this.loadingService.loadingShowSpinner();

            const imageUrl = image.webPath;
            // Upload the image to Firebase Storage
            const uploadedImageUrl = await this.uploadImageToBackend(imageUrl);

            if (!uploadedImageUrl) {
                throw new Error('No se pudo obtener la imagen.');
            }

            // Send FormData to the backend
            let description = await this.sendImageVisionBE(uploadedImageUrl);
            description = 'This is a description of image: ' + description;
            this.messageService.sendMessage(description, 'image', uploadedImageUrl);
        } catch (err) {
            console.log('Error taking photo:', err);
            this.sweetAlertsService.show('error', 'Oops...', 'Error to take photo', 'dark');
        } finally {
            this.loadingService.loadingHideSpinner();
        }
    }

    private async uploadImageToBackend(imageUrlLocal: string): Promise<string> {
        const user = await this.userDataService.checkUserDataLoaded();
        const response = await fetch(imageUrlLocal);
        const blob = await response.blob();
        const filePath = `mirai/images/chats/${user.uid}/vision/${Date.now()}_image.jpg`;

        const downloadURL = await this.storageApiService.addStorageApi(blob, filePath);

        return downloadURL;
    }

    async sendImageVisionBE(imageUrl: string): Promise<string> {
        try {
            await this.tokensService.decreaseTokens(1);
            const response = await this.visionApiService.getVision(imageUrl);
            return response;
        } catch (err) {
            console.log('Error sending image:', err);
            throw err;
        }
    }

    async checkCameraAndStoragePermission() {
        const status = await Camera.checkPermissions();
        return status;
    }

    async requestCameraAndStoragePermission() {
        const cameraPermission = await Camera.requestPermissions({ permissions: ['camera', 'photos'] });
        return cameraPermission;
    }
}
