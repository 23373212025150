import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Injectable({
    providedIn: 'root',
})
export class SuccessLottieService {
    private animationItem: AnimationItem | null = null;
    private readonly platformId = inject(PLATFORM_ID);

    public loadAnimation(): void {
        if (isPlatformBrowser(this.platformId)) {
            const container = document.getElementById('lottie');
            if (container) {
                // Aplicar estilos CSS al contenedor
                Object.assign(container.style, {
                    zIndex: '9999',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    display: 'flex',
                    width: '100vw',
                    height: '100vh',
                });

                const options: AnimationOptions = {
                    path: '../../../assets/lotties/success.json', // Ruta del archivo JSON
                    renderer: 'svg',
                    loop: false,
                    autoplay: false,
                };

                // Destruir la animación existente antes de crear una nueva
                if (this.animationItem) {
                    this.animationItem.destroy();
                }

                import('lottie-web')
                    .then((lottie) => {
                        this.animationItem = lottie.default.loadAnimation({
                            container: container,
                            ...options,
                        });

                        this.animationItem.setSpeed(2.5);

                        this.playAnimation();

                        setTimeout(() => {
                            this.stopAnimation();
                        }, 3250);
                    })
                    .catch((error) => {
                        console.error('Error al cargar lottie-web:', error);
                    });
            } else {
                console.error('No se encontró el elemento con el ID "lottie".');
            }
        }
    }

    public stopAnimation(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.animationItem?.stop();
            const container = document.getElementById('lottie');
            if (container) {
                container.style.display = 'none'; // Ocultar el contenedor
            }
        }
    }

    public playAnimation(): void {
        if (isPlatformBrowser(this.platformId)) {
            const container = document.getElementById('lottie');

            if (container) {
                container.style.display = 'flex'; // Mostrar el contenedor
            }

            this.animationItem?.goToAndPlay(0, true); // Reiniciar la animación desde el principio
        }
    }
}
