import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserDataService } from '@services/firebase/user-data.service';
import { AudioToTextService } from '@services/ia/audio-to-text.service';
import { TokensService } from '@services/purchases/tokens.service';
import { BehaviorSubject, Subscription, interval } from 'rxjs';

@Injectable()
export class CallService {
    isCalling = false;
    private counterSubject = new BehaviorSubject<string>('0:00');
    counter$ = this.counterSubject.asObservable();
    private timerSubscription?: Subscription;

    // Voice recording
    private audioContext: AudioContext | null = null;

    // Observable para emitir eventos de volumen de audio
    private audioVolumeSubject = new BehaviorSubject<number>(0);
    public audioVolume$ = this.audioVolumeSubject.asObservable();

    isRequestInProgress = false;

    constructor(
        private router: Router,
        private tokensService: TokensService,
        public audioToTextService: AudioToTextService,
        private userDataService: UserDataService,
    ) {}

    callVoice() {
        this.isCalling = true;
        this.startTimer();
        this.startRecording();

        setTimeout(() => this.endCall(), 100000);
    }

    private startTimer() {
        let seconds = 0;
        this.timerSubscription = interval(1000).subscribe(async () => {
            seconds++;
            this.updateCounter(seconds);
            let user = await this.userDataService.checkUserDataLoaded();

            if (user.tokens <= 1 || !this.isCalling || user.premium === false) {
                this.stopCall();
                return;
            }
            await this.tokensService.decreaseTokens(1);
        });
    }

    private updateCounter(seconds: number) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedTime = `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
        this.counterSubject.next(formattedTime);
    }

    stopTimer() {
        this.timerSubscription?.unsubscribe();
        this.counterSubject.next('0:00');
    }

    stopCall() {
        this.isCalling = false;
        this.stopTimer();
        this.navigateToDashboard();
        this.isRequestInProgress = false;
        if (this.audioContext) {
            this.audioContext.close();
            this.audioContext = null;
        }
    }

    private endCall() {
        this.isCalling = false;
        this.stopTimer();
    }

    private navigateToDashboard() {
        setTimeout(() => this.router.navigate(['/dashboard/chat']), 800);
    }

    private createAudioContext(): AudioContext {
        return new (window.AudioContext || (window as any).webkitAudioContext)();
    }

    async startRecording() {
        if (this.isRequestInProgress) {
            console.warn('Ya hay una petición en proceso. Por favor, espera a que se complete.');
            return;
        }
        this.isRequestInProgress = true;

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
            this.setupAudioProcessing(stream);
        } catch (err) {
            console.log('Error al iniciar la grabación:', err);
            this.isRequestInProgress = false;
        }
    }

    private setupAudioProcessing(stream: MediaStream) {
        this.audioContext = this.createAudioContext();
        const source = this.audioContext.createMediaStreamSource(stream);
        const processor = this.audioContext.createScriptProcessor(4096, 1, 1);

        source.connect(processor);
        processor.connect(this.audioContext.destination);

        processor.onaudioprocess = this.processAudio.bind(this);
    }

    private processAudio(audioProcessingEvent: AudioProcessingEvent) {
        const inputBuffer = audioProcessingEvent.inputBuffer;
        const inputData = inputBuffer.getChannelData(0);
        const volume = this.calculateVolumeFromInputData(inputData);
        this.emitAudioVolume(volume);
    }

    private emitAudioVolume(volume: number) {
        const scaledVolume = volume * 1000;
        this.audioVolumeSubject.next(scaledVolume);
    }

    private calculateVolumeFromInputData(inputData: Float32Array): number {
        const sum = inputData.reduce((acc, val) => acc + val * val, 0);
        return Math.sqrt(sum / inputData.length);
    }
}
