import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class PromptsApiService {
    constructor(private apiService: ApiService) {}

    async setInitialPromptAPI(body: any): Promise<string> {
        return this.apiService.postRequest('chats/prompt/initialPrompt', body);
    }

    async firstPromptAiApi() {
        return this.apiService.getRequest<string>('chats/prompt/firstPromptAi');
    }

    async updatePersonalityPromptApi(body: any) {
        return this.apiService.postRequest('chats/prompt/updatePersonalityPrompt', body);
    }
}
