import { Injectable } from '@angular/core';
import MemoryData from '@data/memory.json';
import { Memory } from '@models/Memory';

@Injectable()
export class MemoryService {
    memoryData: Memory = MemoryData;

    getMemoryData() {
        return this.memoryData;
    }

    async saveMemoryData(formData: Memory) {
        // const memoryData = formData as Memory;
    }
}
