import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class TextToSpeechAPIService {
    audio = new Audio();

    constructor(private apiService: ApiService) {}

    getVoicesApi(): Promise<any> {
        return this.apiService.getRequest<any>('text_to_audio/get-voices');
    }

    async textToSpeech(txt: string, idVoice: string, pitch: number, speakingRate: number, voiceType: string, voiceCall: boolean): Promise<any> {
        let endpointTxt2Speech;
        if (voiceType === 'default' || voiceCall) {
            // default or pro (Google or Elevenlabs)
            endpointTxt2Speech = 'get-txt2speech-openai';
        } else if (voiceType === 'pro') {
            endpointTxt2Speech = 'get-txt2speech-elevenlabs';
            // endpointTxt2Speech = 'get-txt2speech-google';
        }

        const body = {
            txt,
            idVoice,
            pitch, // Ajusta este valor para cambiar el tono
            speakingRate: speakingRate, // Ajusta este valor para cambiar la velocidad
        };

        return this.apiService.postRequest<any>('text_to_audio/' + endpointTxt2Speech, body);
    }
}
