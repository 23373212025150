import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';
@Injectable()
export class RewardsApiService {
    constructor(private apiService: ApiService) {}

    // Save reward to database
    saveRewardToDatabase(body: any) {
        return this.apiService.postRequest<any>('rewards/update', body);
    }
}
