import { Injectable } from '@angular/core';
import { UserData } from '@models/User';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class AvatarsApiService {
    constructor(private apiService: ApiService) {}

    // Get all avatars
    async getAvatarsApi() {
        return await this.apiService.getRequest<any>('users/avatars/getAll');
    }

    // Update a user
    async updateAvatarApi(idAvatar: string, body: any) {
        return await this.apiService.postRequest<UserData>(`users/avatars/update/${idAvatar}`, body);
    }

    // Get avatar selected
    async getAvatarSelectedApi(avatar: string) {
        return this.apiService.getRequest(`users/avatars/get/${avatar}`);
    }
}
