{
    "about": ["you", "bot"],
    "category": ["career", "daily life", "education", "favorites", "personal"],
    "career": {
        "type": ["career field", "career goals", "job", "job satisfaction", "work schedule"],
        "career field": [],
        "career goals": [],
        "job": [],
        "job satisfaction": ["experience burnout", "hating job", "just got fired", "just got hired", "just got promoted", "looking for a new job", "loving job", "planning to quit"],
        "work schedule": ["9 to 5", "flexible", "freelance", "full-time", "part-time", "seasonal", "shift work", "work from home"]
    },
    "daily life": {
        "type": ["city", "eating habits", "fitness routine", "lifestyle"],
        "city": "",
        "country": [
            "afghanistan",
            "åland islands",
            "albania",
            "algeria",
            "american samoa",
            "andorra",
            "angola",
            "anguilla",
            "antarctica",
            "antigua and barbuda",
            "argentina",
            "armenia",
            "aruba",
            "australia",
            "austria",
            "azerbaijan",
            "bahamas",
            "bahrain",
            "bangladesh",
            "barbados",
            "belarus",
            "belgium",
            "belize",
            "benin",
            "bermuda",
            "bhutan",
            "bolivia",
            "bosnia and herzegovina",
            "botswana",
            "bouvet island",
            "brazil",
            "british indian ocean territory",
            "brunei darussalam",
            "bulgaria",
            "burkina faso",
            "burundi",
            "cambodia",
            "cameroon",
            "canada",
            "cape verde",
            "cayman islands",
            "central african republic",
            "chad",
            "chile",
            "china",
            "christmas island",
            "cocos (keeling) islands",
            "colombia",
            "comoros",
            "congo",
            "congo, the democratic republic of the",
            "cook islands",
            "costa rica",
            "cote d'ivoire",
            "croatia",
            "cuba",
            "cyprus",
            "czech republic",
            "denmark",
            "djibouti",
            "dominica",
            "dominican republic",
            "ecuador",
            "egypt",
            "el salvador",
            "equatorial guinea",
            "eritrea",
            "estonia",
            "ethiopia",
            "falkland islands (malvinas)",
            "faroe islands",
            "fiji",
            "finland",
            "france",
            "french guiana",
            "french polynesia",
            "french southern territories",
            "gabon",
            "gambia",
            "georgia",
            "germany",
            "ghana",
            "gibraltar",
            "greece",
            "greenland",
            "grenada",
            "guadeloupe",
            "guam",
            "guatemala",
            "guernsey",
            "guinea",
            "guinea-bissau",
            "guyana",
            "haiti",
            "heard island and mcdonald islands",
            "holy see (vatican city state)",
            "honduras",
            "hong kong",
            "hungary",
            "iceland",
            "india",
            "indonesia",
            "iran, islamic republic of",
            "iraq",
            "ireland",
            "isle of man",
            "israel",
            "italy",
            "jamaica",
            "japan",
            "jersey",
            "jordan",
            "kazakhstan",
            "kenya",
            "kiribati",
            "korea, democratic people's republic of",
            "korea, republic of",
            "kuwait",
            "kyrgyzstan",
            "lao people's democratic republic",
            "latvia",
            "lebanon",
            "lesotho",
            "liberia",
            "libyan arab jamahiriya",
            "liechtenstein",
            "lithuania",
            "luxembourg",
            "macao",
            "macedonia, the former yugoslav republic of",
            "madagascar",
            "malawi",
            "malaysia",
            "maldives",
            "mali",
            "malta",
            "marshall islands",
            "martinique",
            "mauritania",
            "mauritius",
            "mayotte",
            "mexico",
            "micronesia, federated states of",
            "moldova, republic of",
            "monaco",
            "mongolia",
            "montserrat",
            "morocco",
            "mozambique",
            "myanmar",
            "namibia",
            "nauru",
            "nepal",
            "netherlands",
            "netherlands antilles",
            "new caledonia",
            "new zealand",
            "nicaragua",
            "niger",
            "nigeria",
            "niue",
            "norfolk island",
            "northern mariana islands",
            "norway",
            "oman",
            "pakistan",
            "palau",
            "palestinian territory, occupied",
            "panama",
            "papua new guinea",
            "paraguay",
            "peru",
            "philippines",
            "pitcairn",
            "poland",
            "portugal",
            "puerto rico",
            "qatar",
            "reunion",
            "romania",
            "russian federation",
            "rwanda",
            "saint helena",
            "saint kitts and nevis",
            "saint lucia",
            "saint pierre and miquelon",
            "saint vincent and the grenadines",
            "samoa",
            "san marino",
            "sao tome and principe",
            "saudi arabia",
            "senegal",
            "serbia and montenegro",
            "seychelles",
            "sierra leone",
            "singapore",
            "slovakia",
            "slovenia",
            "solomon islands",
            "somalia",
            "south africa",
            "south georgia and the south sandwich islands",
            "spain",
            "sri lanka",
            "sudan",
            "suriname",
            "svalbard and jan mayen",
            "swaziland",
            "sweden",
            "switzerland",
            "syrian arab republic",
            "taiwan, province of china",
            "tajikistan",
            "tanzania, united republic of",
            "thailand",
            "timor-leste",
            "togo",
            "tokelau",
            "tonga",
            "trinidad and tobago",
            "tunisia",
            "turkey",
            "turkmenistan",
            "turks and caicos islands",
            "tuvalu",
            "uganda",
            "ukraine",
            "united arab emirates",
            "united kingdom",
            "united states",
            "united states minor outlying islands",
            "uruguay",
            "uzbekistan",
            "vanuatu",
            "venezuela",
            "vietnam",
            "virgin islands, british",
            "virgin islands, u.s.",
            "wallis and futuna",
            "western sahara",
            "yemen",
            "zambia",
            "zimbabwe"
        ],
        "eating habits": ["eating out", "flexitarian", "frutarian", "keto diet", "on a diet", "pescetarian", "raw foo diet", "vegan", "vegetarian"],
        "fitness routine": [
            "acrobatics",
            "aerobics",
            "at work",
            "ballet",
            "basketball",
            "bike",
            "bodybuilding",
            "bodyweight exercises",
            "calisthenics",
            "cardio",
            "crossfit",
            "cycling",
            "dancing",
            "football",
            "gymnastics",
            "hiking",
            "jogging",
            "martial arts",
            "pilates",
            "running",
            "soccer",
            "swimming",
            "tennis",
            "walking",
            "weightlifting",
            "yoga"
        ],
        "lifestyke": ["college", "home", "job search", "retired", "school", "something else", "stay at home parent", "unemployed", "university", "work"],
        "living situation": ["child", "flatmates", "friends", "no one", "partner", "pet", "relatives", "roommates", "spouse", "with parents"],
        "sleep routine": ["gettingenough sleep", "going to bed early", "going to bed late", "having trouble sleeping", "not getting enough sleep", "sleeping too much", "rising early", "struggling to sleep", "waking up early", "waking up late"]
    },
    "education": {
        "type": ["academic degree", "academic goals", "college major", "subjects you dislike", "subjects you like"],
        "academic degree": ["associate's degree", "bachelor's degree", "doctorate", "high school diploma", "master's degree", "no degree", "professional degree", "some college"],
        "academic goals": [],
        "college major": [],
        "subjects you dislike": [],
        "subjects you like": []
    },
    "favorites": {
        "type": [
            "dream travel destinations",
            "favorite tv genres",
            "favorite tv shows",
            "favorite actors",
            "favorite animals",
            "favorite authors",
            "favorite book genres",
            "favorite books",
            "favorite colors",
            "favorite drinks",
            "favorite food",
            "favorite movie genres",
            "favorite movies",
            "favorite music",
            "favorite music genres",
            "favorite season",
            "favorite songs",
            "favorite sports",
            "favorite video games genres",
            "favorite video games",
            "lucky number"
        ],
        "dream travel destinations": [
            "afghanistan",
            "åland islands",
            "albania",
            "algeria",
            "american samoa",
            "andorra",
            "angola",
            "anguilla",
            "antarctica",
            "antigua and barbuda",
            "argentina",
            "armenia",
            "aruba",
            "australia",
            "austria",
            "azerbaijan",
            "bahamas",
            "bahrain",
            "bangladesh",
            "barbados",
            "belarus",
            "belgium",
            "belize",
            "benin",
            "bermuda",
            "bhutan",
            "bolivia",
            "bosnia and herzegovina",
            "botswana",
            "bouvet island",
            "brazil",
            "british indian ocean territory",
            "brunei darussalam",
            "bulgaria",
            "burkina faso",
            "burundi",
            "cambodia",
            "cameroon",
            "canada",
            "cape verde",
            "cayman islands",
            "central african republic",
            "chad",
            "chile",
            "china",
            "christmas island",
            "cocos (keeling) islands",
            "colombia",
            "comoros",
            "congo",
            "congo, the democratic republic of the",
            "cook islands",
            "costa rica",
            "cote d'ivoire",
            "croatia",
            "cuba",
            "cyprus",
            "czech republic",
            "denmark",
            "djibouti",
            "dominica",
            "dominican republic",
            "ecuador",
            "egypt",
            "el salvador",
            "equatorial guinea",
            "eritrea",
            "estonia",
            "ethiopia",
            "falkland islands (malvinas)",
            "faroe islands",
            "fiji",
            "finland",
            "france",
            "french guiana",
            "french polynesia",
            "french southern territories",
            "gabon",
            "gambia",
            "georgia",
            "germany",
            "ghana",
            "gibraltar",
            "greece",
            "greenland",
            "grenada",
            "guadeloupe",
            "guam",
            "guatemala",
            "guernsey",
            "guinea",
            "guinea-bissau",
            "guyana",
            "haiti",
            "heard island and mcdonald islands",
            "holy see (vatican city state)",
            "honduras",
            "hong kong",
            "hungary",
            "iceland",
            "india",
            "indonesia",
            "iran, islamic republic of",
            "iraq",
            "ireland",
            "isle of man",
            "israel",
            "italy",
            "jamaica",
            "japan",
            "jersey",
            "jordan",
            "kazakhstan",
            "kenya",
            "kiribati",
            "korea, democratic people's republic of",
            "korea, republic of",
            "kuwait",
            "kyrgyzstan",
            "lao people's democratic republic",
            "latvia",
            "lebanon",
            "lesotho",
            "liberia",
            "libyan arab jamahiriya",
            "liechtenstein",
            "lithuania",
            "luxembourg",
            "macao",
            "macedonia, the former yugoslav republic of",
            "madagascar",
            "malawi",
            "malaysia",
            "maldives",
            "mali",
            "malta",
            "marshall islands",
            "martinique",
            "mauritania",
            "mauritius",
            "mayotte",
            "mexico",
            "micronesia, federated states of",
            "moldova, republic of",
            "monaco",
            "mongolia",
            "montserrat",
            "morocco",
            "mozambique",
            "myanmar",
            "namibia",
            "nauru",
            "nepal",
            "netherlands",
            "netherlands antilles",
            "new caledonia",
            "new zealand",
            "nicaragua",
            "niger",
            "nigeria",
            "niue",
            "norfolk island",
            "northern mariana islands",
            "norway",
            "oman",
            "pakistan",
            "palau",
            "palestinian territory, occupied",
            "panama",
            "papua new guinea",
            "paraguay",
            "peru",
            "philippines",
            "pitcairn",
            "poland",
            "portugal",
            "puerto rico",
            "qatar",
            "reunion",
            "romania",
            "russian federation",
            "rwanda",
            "saint helena",
            "saint kitts and nevis",
            "saint lucia",
            "saint pierre and miquelon",
            "saint vincent and the grenadines",
            "samoa",
            "san marino",
            "sao tome and principe",
            "saudi arabia",
            "senegal",
            "serbia and montenegro",
            "seychelles",
            "sierra leone",
            "singapore",
            "slovakia",
            "slovenia",
            "solomon islands",
            "somalia",
            "south africa",
            "south georgia and the south sandwich islands",
            "spain",
            "sri lanka",
            "sudan",
            "suriname",
            "svalbard and jan mayen",
            "swaziland",
            "sweden",
            "switzerland",
            "syrian arab republic",
            "taiwan, province of china",
            "tajikistan",
            "tanzania, united republic of",
            "thailand",
            "timor-leste",
            "togo",
            "tokelau",
            "tonga",
            "trinidad and tobago",
            "tunisia",
            "turkey",
            "turkmenistan",
            "turks and caicos islands",
            "tuvalu",
            "uganda",
            "ukraine",
            "united arab emirates",
            "united kingdom",
            "united states",
            "united states minor outlying islands",
            "uruguay",
            "uzbekistan",
            "vanuatu",
            "venezuela",
            "vietnam",
            "virgin islands, british",
            "virgin islands, u.s.",
            "wallis and futuna",
            "western sahara",
            "yemen",
            "zambia",
            "zimbabwe"
        ],
        "favorite tv genres": ["action", "adventure", "animation", "anime", "comedy", "crime", "documentary", "drama", "family", "fantasy", "history", "horror", "kids", "mystery", "news", "reality", "romance", "science fiction", "soap", "talk", "thriller", "war", "western"],
        "favorite tv shows": [],
        "favorite actors": [],
        "favorite animals": [
            "all",
            "alpaca",
            "armadillo",
            "bat",
            "bear",
            "beaver",
            "bee",
            "bird",
            "buffalo",
            "butterfly",
            "camel",
            "cat",
            "cheetah",
            "chicken",
            "chimpanzee",
            "cow",
            "crab",
            "crocodile",
            "deer",
            "dog",
            "dolphin",
            "duck",
            "eagle",
            "elephant",
            "fish",
            "flamingo",
            "fly",
            "fox",
            "frog",
            "giraffe",
            "goat",
            "goldfish",
            "hamster",
            "hippopotamus",
            "horse",
            "kangaroo",
            "kitten",
            "lion",
            "lobster",
            "monkey",
            "octopus",
            "owl",
            "panda",
            "pig",
            "puppy",
            "rabbit",
            "rat",
            "scorpion",
            "seal",
            "shark",
            "sheep",
            "snail",
            "snake",
            "spider",
            "squirrel",
            "tiger",
            "turtle",
            "wolf",
            "zebra"
        ],
        "favorite authors": [],
        "favorite book genres": [
            "action",
            "adventure",
            "biography",
            "children's",
            "comic",
            "cookbook",
            "crime",
            "diary",
            "dictionary",
            "drama",
            "encyclopedia",
            "fairytale",
            "fantasy",
            "guide",
            "health",
            "history",
            "horror",
            "journal",
            "math",
            "mystery",
            "poetry",
            "religion",
            "romance",
            "science",
            "science fiction",
            "self help",
            "thriller",
            "travel"
        ],
        "favorite books": [],
        "favorite colors": [
            "ambrosia",
            "aqua",
            "aquamarine",
            "azure",
            "beige",
            "black",
            "blue",
            "blush",
            "bronze",
            "brown",
            "burgundy",
            "cerulean",
            "champagne",
            "chocolate",
            "copper",
            "coral",
            "cream",
            "crimson",
            "cyan",
            "emerald",
            "fuchsia",
            "gold",
            "gray",
            "green",
            "indigo",
            "ivory",
            "jade",
            "lavender",
            "lemon",
            "lilac",
            "lime",
            "magenta",
            "maroon",
            "mauve",
            "navy",
            "olive",
            "orange",
            "peach",
            "pink",
            "plum",
            "purple",
            "red",
            "rose",
            "ruby",
            "salmon",
            "sapphire",
            "scarlet",
            "silver",
            "tan",
            "taupe",
            "teal",
            "turquoise",
            "violet",
            "white",
            "yellow"
        ],
        "favorite drinks": [],
        "favorite food": [],
        "favorite movie genres": ["action", "adventure", "animation", "anime", "comedy", "crime", "documentary", "drama", "family", "fantasy", "history", "horror", "kids", "mystery", "news", "reality", "romance", "science fiction", "soap", "talk", "thriller", "war", "western"],
        "favorite movies": [],
        "favorite music": [],
        "favorite music genres": [
            "alternative",
            "ambient",
            "blues",
            "classical",
            "country",
            "dance",
            "disco",
            "dubstep",
            "electronic",
            "folk",
            "funk",
            "gospel",
            "grunge",
            "hard rock",
            "heavy metal",
            "hip hop",
            "house",
            "indie",
            "jazz",
            "latin",
            "new age",
            "opera",
            "pop",
            "punk",
            "r&b",
            "rap",
            "reggae",
            "rock",
            "rock and roll",
            "soul",
            "techno",
            "trance"
        ],
        "favorite season": ["autumn", "spring", "summer", "winter"],
        "favorite songs": [],
        "favorite sports": [
            "aerobics",
            "american football",
            "badminton",
            "baseball",
            "basketball",
            "bodybuilding",
            "boxing",
            "calisthenics",
            "cardio",
            "cricket",
            "crossfit",
            "cycling",
            "dancing",
            "football",
            "gymnastics",
            "hiking",
            "jogging",
            "martial arts",
            "pilates",
            "running",
            "soccer",
            "swimming",
            "tennis",
            "walking",
            "weightlifting",
            "yoga"
        ],
        "favorite video games genres": ["action", "adventure", "arcade", "board", "card", "casino", "casual", "educational", "family", "fighting", "music", "platform", "puzzle", "racing", "role-playing", "simulation", "sports", "strategy", "trivia", "word"],
        "favorite video games": [],
        "lucky number": ""
    },
    "personal": {
        "type": ["3 words that describe you best", "goals", "good habits", "growth area", "interests", "life events", "life values", "love language", "pet peeves", "relationship status", "sexuality", "zodiac sign"],
        "3 words that describe you best": [],
        "goals": [],
        "good habits": [],
        "growth area": [],
        "interests": [
            "acting",
            "action/adventure movies",
            "amateur radio",
            "animals",
            "animal & awws",
            "anime",
            "archaeology",
            "astronomy",
            "athletics",
            "backpacking",
            "baking",
            "ballet",
            "basketball",
            "beaches",
            "bird watching",
            "board games",
            "boating",
            "book clubs",
            "brewing beer",
            "camping",
            "cartooning",
            "ceramics",
            "chess",
            "classical music",
            "climbing",
            "comedy",
            "computer programming",
            "cooking",
            "crafts",
            "cycling",
            "dance",
            "digital art",
            "drama",
            "drawing",
            "drone racing",
            "eco-friendly living",
            "electronic music",
            "fashion",
            "fishing",
            "fitness",
            "flower arranging",
            "folk music",
            "foodie culture",
            "football",
            "gardening",
            "golf",
            "guitar playing",
            "hiking",
            "history",
            "horseback riding",
            "hot air ballooning",
            "ice skating",
            "jazz music",
            "jewelry making",
            "jogging",
            "journaling",
            "juggling",
            "karaoke"
        ],
        "life events": [],
        "life values": [],
        "love language": ["acts of service", "gift giving", "physical touch", "quality time", "words of affirmation"],
        "pet peeves": [],
        "relationship status": ["dating", "divorced", "engaged", "in a relationship", "married", "separated", "single", "widowed"],
        "sexuality": ["asexual", "bisexual", "demisexual", "gay", "graysexual", "heterosexual", "lesbian", "omnisexual", "pansexual", "polysexual", "queer", "questioning", "sapiosexual", "straight"],
        "zodiac sign": ["aries", "taurus", "gemini", "cancer", "leo", "virgo", "libra", "scorpio", "sagittarius", "capricorn", "aquarius", "pisces"]
    }
}
