[
    {
        "identifier": "annual_subscription",
        "serverDescription": "annual_subscription",
        "metadata": {},
        "availablePackages": [
            {
                "identifier": "$rc_annual",
                "packageType": "ANNUAL",
                "product": {
                    "identifier": "annual_subscription:annual-subscription",
                    "description": "",
                    "title": "annual_subscription (AI Girl: Virtual AI Girlfriend)",
                    "price": 79.99,
                    "priceString": "€79.99",
                    "currencyCode": "EUR",
                    "introPrice": null,
                    "discounts": null,
                    "productCategory": "SUBSCRIPTION",
                    "productType": "AUTO_RENEWABLE_SUBSCRIPTION",
                    "subscriptionPeriod": "P1Y",
                    "defaultOption": {
                        "id": "annual-subscription",
                        "storeProductId": "annual_subscription:annual-subscription",
                        "productId": "annual_subscription",
                        "pricingPhases": [
                            {
                                "billingPeriod": {
                                    "unit": "YEAR",
                                    "value": 1,
                                    "iso8601": "P1Y"
                                },
                                "recurrenceMode": 1,
                                "billingCycleCount": 0,
                                "price": {
                                    "formatted": "€79.99",
                                    "amountMicros": 79990000,
                                    "currencyCode": "EUR"
                                },
                                "offerPaymentMode": null
                            }
                        ],
                        "tags": [],
                        "isBasePlan": true,
                        "billingPeriod": {
                            "unit": "YEAR",
                            "value": 1,
                            "iso8601": "P1Y"
                        },
                        "isPrepaid": false,
                        "fullPricePhase": {
                            "billingPeriod": {
                                "unit": "YEAR",
                                "value": 1,
                                "iso8601": "P1Y"
                            },
                            "recurrenceMode": 1,
                            "billingCycleCount": 0,
                            "price": {
                                "formatted": "€79.99",
                                "amountMicros": 79990000,
                                "currencyCode": "EUR"
                            },
                            "offerPaymentMode": null
                        },
                        "freePhase": null,
                        "introPhase": null,
                        "presentedOfferingIdentifier": "annual_subscription"
                    },
                    "subscriptionOptions": [
                        {
                            "id": "annual-subscription",
                            "storeProductId": "annual_subscription:annual-subscription",
                            "productId": "annual_subscription",
                            "pricingPhases": [
                                {
                                    "billingPeriod": {
                                        "unit": "YEAR",
                                        "value": 1,
                                        "iso8601": "P1Y"
                                    },
                                    "recurrenceMode": 1,
                                    "billingCycleCount": 0,
                                    "price": {
                                        "formatted": "€79.99",
                                        "amountMicros": 79990000,
                                        "currencyCode": "EUR"
                                    },
                                    "offerPaymentMode": null
                                }
                            ],
                            "tags": [],
                            "isBasePlan": true,
                            "billingPeriod": {
                                "unit": "YEAR",
                                "value": 1,
                                "iso8601": "P1Y"
                            },
                            "isPrepaid": false,
                            "fullPricePhase": {
                                "billingPeriod": {
                                    "unit": "YEAR",
                                    "value": 1,
                                    "iso8601": "P1Y"
                                },
                                "recurrenceMode": 1,
                                "billingCycleCount": 0,
                                "price": {
                                    "formatted": "€79.99",
                                    "amountMicros": 79990000,
                                    "currencyCode": "EUR"
                                },
                                "offerPaymentMode": null
                            },
                            "freePhase": null,
                            "introPhase": null,
                            "presentedOfferingIdentifier": "annual_subscription"
                        }
                    ],
                    "presentedOfferingIdentifier": "annual_subscription"
                },
                "offeringIdentifier": "annual_subscription"
            }
        ],
        "lifetime": null,
        "annual": {
            "identifier": "$rc_annual",
            "packageType": "ANNUAL",
            "product": {
                "identifier": "annual_subscription:annual-subscription",
                "description": "",
                "title": "annual_subscription (AI Girl: Virtual AI Girlfriend)",
                "price": 79.99,
                "priceString": "€79.99",
                "currencyCode": "EUR",
                "introPrice": null,
                "discounts": null,
                "productCategory": "SUBSCRIPTION",
                "productType": "AUTO_RENEWABLE_SUBSCRIPTION",
                "subscriptionPeriod": "P1Y",
                "defaultOption": {
                    "id": "annual-subscription",
                    "storeProductId": "annual_subscription:annual-subscription",
                    "productId": "annual_subscription",
                    "pricingPhases": [
                        {
                            "billingPeriod": {
                                "unit": "YEAR",
                                "value": 1,
                                "iso8601": "P1Y"
                            },
                            "recurrenceMode": 1,
                            "billingCycleCount": 0,
                            "price": {
                                "formatted": "€79.99",
                                "amountMicros": 79990000,
                                "currencyCode": "EUR"
                            },
                            "offerPaymentMode": null
                        }
                    ],
                    "tags": [],
                    "isBasePlan": true,
                    "billingPeriod": {
                        "unit": "YEAR",
                        "value": 1,
                        "iso8601": "P1Y"
                    },
                    "isPrepaid": false,
                    "fullPricePhase": {
                        "billingPeriod": {
                            "unit": "YEAR",
                            "value": 1,
                            "iso8601": "P1Y"
                        },
                        "recurrenceMode": 1,
                        "billingCycleCount": 0,
                        "price": {
                            "formatted": "€79.99",
                            "amountMicros": 79990000,
                            "currencyCode": "EUR"
                        },
                        "offerPaymentMode": null
                    },
                    "freePhase": null,
                    "introPhase": null,
                    "presentedOfferingIdentifier": "annual_subscription"
                },
                "subscriptionOptions": [
                    {
                        "id": "annual-subscription",
                        "storeProductId": "annual_subscription:annual-subscription",
                        "productId": "annual_subscription",
                        "pricingPhases": [
                            {
                                "billingPeriod": {
                                    "unit": "YEAR",
                                    "value": 1,
                                    "iso8601": "P1Y"
                                },
                                "recurrenceMode": 1,
                                "billingCycleCount": 0,
                                "price": {
                                    "formatted": "€79.99",
                                    "amountMicros": 79990000,
                                    "currencyCode": "EUR"
                                },
                                "offerPaymentMode": null
                            }
                        ],
                        "tags": [],
                        "isBasePlan": true,
                        "billingPeriod": {
                            "unit": "YEAR",
                            "value": 1,
                            "iso8601": "P1Y"
                        },
                        "isPrepaid": false,
                        "fullPricePhase": {
                            "billingPeriod": {
                                "unit": "YEAR",
                                "value": 1,
                                "iso8601": "P1Y"
                            },
                            "recurrenceMode": 1,
                            "billingCycleCount": 0,
                            "price": {
                                "formatted": "€79.99",
                                "amountMicros": 79990000,
                                "currencyCode": "EUR"
                            },
                            "offerPaymentMode": null
                        },
                        "freePhase": null,
                        "introPhase": null,
                        "presentedOfferingIdentifier": "annual_subscription"
                    }
                ],
                "presentedOfferingIdentifier": "annual_subscription"
            },
            "offeringIdentifier": "annual_subscription"
        },
        "sixMonth": null,
        "threeMonth": null,
        "twoMonth": null,
        "monthly": null,
        "weekly": null
    },
    {
        "identifier": "monthly_subscription",
        "serverDescription": "monthly_subscription",
        "metadata": {},
        "availablePackages": [
            {
                "identifier": "$rc_monthly",
                "packageType": "MONTHLY",
                "product": {
                    "identifier": "monthly_subscription:monthly-subscription",
                    "description": "",
                    "title": "monthly_subscription (AI Girl: Virtual AI Girlfriend)",
                    "price": 10.99,
                    "priceString": "€10.99",
                    "currencyCode": "EUR",
                    "introPrice": null,
                    "discounts": null,
                    "productCategory": "SUBSCRIPTION",
                    "productType": "AUTO_RENEWABLE_SUBSCRIPTION",
                    "subscriptionPeriod": "P1M",
                    "defaultOption": {
                        "id": "monthly-subscription",
                        "storeProductId": "monthly_subscription:monthly-subscription",
                        "productId": "monthly_subscription",
                        "pricingPhases": [
                            {
                                "billingPeriod": {
                                    "unit": "MONTH",
                                    "value": 1,
                                    "iso8601": "P1M"
                                },
                                "recurrenceMode": 1,
                                "billingCycleCount": 0,
                                "price": {
                                    "formatted": "€10.99",
                                    "amountMicros": 10990000,
                                    "currencyCode": "EUR"
                                },
                                "offerPaymentMode": null
                            }
                        ],
                        "tags": [],
                        "isBasePlan": true,
                        "billingPeriod": {
                            "unit": "MONTH",
                            "value": 1,
                            "iso8601": "P1M"
                        },
                        "isPrepaid": false,
                        "fullPricePhase": {
                            "billingPeriod": {
                                "unit": "MONTH",
                                "value": 1,
                                "iso8601": "P1M"
                            },
                            "recurrenceMode": 1,
                            "billingCycleCount": 0,
                            "price": {
                                "formatted": "€10.99",
                                "amountMicros": 10990000,
                                "currencyCode": "EUR"
                            },
                            "offerPaymentMode": null
                        },
                        "freePhase": null,
                        "introPhase": null,
                        "presentedOfferingIdentifier": "monthly_subscription"
                    },
                    "subscriptionOptions": [
                        {
                            "id": "monthly-subscription",
                            "storeProductId": "monthly_subscription:monthly-subscription",
                            "productId": "monthly_subscription",
                            "pricingPhases": [
                                {
                                    "billingPeriod": {
                                        "unit": "MONTH",
                                        "value": 1,
                                        "iso8601": "P1M"
                                    },
                                    "recurrenceMode": 1,
                                    "billingCycleCount": 0,
                                    "price": {
                                        "formatted": "€10.99",
                                        "amountMicros": 10990000,
                                        "currencyCode": "EUR"
                                    },
                                    "offerPaymentMode": null
                                }
                            ],
                            "tags": [],
                            "isBasePlan": true,
                            "billingPeriod": {
                                "unit": "MONTH",
                                "value": 1,
                                "iso8601": "P1M"
                            },
                            "isPrepaid": false,
                            "fullPricePhase": {
                                "billingPeriod": {
                                    "unit": "MONTH",
                                    "value": 1,
                                    "iso8601": "P1M"
                                },
                                "recurrenceMode": 1,
                                "billingCycleCount": 0,
                                "price": {
                                    "formatted": "€10.99",
                                    "amountMicros": 10990000,
                                    "currencyCode": "EUR"
                                },
                                "offerPaymentMode": null
                            },
                            "freePhase": null,
                            "introPhase": null,
                            "presentedOfferingIdentifier": "monthly_subscription"
                        }
                    ],
                    "presentedOfferingIdentifier": "monthly_subscription"
                },
                "offeringIdentifier": "monthly_subscription"
            }
        ],
        "lifetime": null,
        "annual": null,
        "sixMonth": null,
        "threeMonth": null,
        "twoMonth": null,
        "monthly": {
            "identifier": "$rc_monthly",
            "packageType": "MONTHLY",
            "product": {
                "identifier": "monthly_subscription:monthly-subscription",
                "description": "",
                "title": "monthly_subscription (AI Girl: Virtual AI Girlfriend)",
                "price": 10.99,
                "priceString": "€10.99",
                "currencyCode": "EUR",
                "introPrice": null,
                "discounts": null,
                "productCategory": "SUBSCRIPTION",
                "productType": "AUTO_RENEWABLE_SUBSCRIPTION",
                "subscriptionPeriod": "P1M",
                "defaultOption": {
                    "id": "monthly-subscription",
                    "storeProductId": "monthly_subscription:monthly-subscription",
                    "productId": "monthly_subscription",
                    "pricingPhases": [
                        {
                            "billingPeriod": {
                                "unit": "MONTH",
                                "value": 1,
                                "iso8601": "P1M"
                            },
                            "recurrenceMode": 1,
                            "billingCycleCount": 0,
                            "price": {
                                "formatted": "€10.99",
                                "amountMicros": 10990000,
                                "currencyCode": "EUR"
                            },
                            "offerPaymentMode": null
                        }
                    ],
                    "tags": [],
                    "isBasePlan": true,
                    "billingPeriod": {
                        "unit": "MONTH",
                        "value": 1,
                        "iso8601": "P1M"
                    },
                    "isPrepaid": false,
                    "fullPricePhase": {
                        "billingPeriod": {
                            "unit": "MONTH",
                            "value": 1,
                            "iso8601": "P1M"
                        },
                        "recurrenceMode": 1,
                        "billingCycleCount": 0,
                        "price": {
                            "formatted": "€10.99",
                            "amountMicros": 10990000,
                            "currencyCode": "EUR"
                        },
                        "offerPaymentMode": null
                    },
                    "freePhase": null,
                    "introPhase": null,
                    "presentedOfferingIdentifier": "monthly_subscription"
                },
                "subscriptionOptions": [
                    {
                        "id": "monthly-subscription",
                        "storeProductId": "monthly_subscription:monthly-subscription",
                        "productId": "monthly_subscription",
                        "pricingPhases": [
                            {
                                "billingPeriod": {
                                    "unit": "MONTH",
                                    "value": 1,
                                    "iso8601": "P1M"
                                },
                                "recurrenceMode": 1,
                                "billingCycleCount": 0,
                                "price": {
                                    "formatted": "€10.99",
                                    "amountMicros": 10990000,
                                    "currencyCode": "EUR"
                                },
                                "offerPaymentMode": null
                            }
                        ],
                        "tags": [],
                        "isBasePlan": true,
                        "billingPeriod": {
                            "unit": "MONTH",
                            "value": 1,
                            "iso8601": "P1M"
                        },
                        "isPrepaid": false,
                        "fullPricePhase": {
                            "billingPeriod": {
                                "unit": "MONTH",
                                "value": 1,
                                "iso8601": "P1M"
                            },
                            "recurrenceMode": 1,
                            "billingCycleCount": 0,
                            "price": {
                                "formatted": "€10.99",
                                "amountMicros": 10990000,
                                "currencyCode": "EUR"
                            },
                            "offerPaymentMode": null
                        },
                        "freePhase": null,
                        "introPhase": null,
                        "presentedOfferingIdentifier": "monthly_subscription"
                    }
                ],
                "presentedOfferingIdentifier": "monthly_subscription"
            },
            "offeringIdentifier": "monthly_subscription"
        },
        "weekly": null
    },
    {
        "identifier": "100_tokens",
        "serverDescription": "100_tokens",
        "metadata": {},
        "availablePackages": [
            {
                "identifier": "100_tokens",
                "packageType": "CUSTOM",
                "product": {
                    "identifier": "100_tokens",
                    "description": "100_tokens",
                    "title": "100_tokens (AI Girl: Virtual AI Girlfriend)",
                    "price": 1.79,
                    "priceString": "€1.79",
                    "currencyCode": "EUR",
                    "introPrice": null,
                    "discounts": null,
                    "productCategory": "NON_SUBSCRIPTION",
                    "productType": "CONSUMABLE",
                    "subscriptionPeriod": null,
                    "defaultOption": null,
                    "subscriptionOptions": null,
                    "presentedOfferingIdentifier": "100_tokens"
                },
                "offeringIdentifier": "100_tokens"
            }
        ],
        "lifetime": null,
        "annual": null,
        "sixMonth": null,
        "threeMonth": null,
        "twoMonth": null,
        "monthly": null,
        "weekly": null,
        "attributes": {
            "tokens": 100,
            "price": "1.79 EUR",
            "tag": ""
        }
    },
    {
        "identifier": "300_tokens",
        "serverDescription": "300_tokens",
        "metadata": {},
        "availablePackages": [
            {
                "identifier": "300_tokens",
                "packageType": "CUSTOM",
                "product": {
                    "identifier": "300_tokens",
                    "description": "300_tokens",
                    "title": "300_tokens (AI Girl: Virtual AI Girlfriend)",
                    "price": 3.59,
                    "priceString": "€3.59",
                    "currencyCode": "EUR",
                    "introPrice": null,
                    "discounts": null,
                    "productCategory": "NON_SUBSCRIPTION",
                    "productType": "CONSUMABLE",
                    "subscriptionPeriod": null,
                    "defaultOption": null,
                    "subscriptionOptions": null,
                    "presentedOfferingIdentifier": "300_tokens"
                },
                "offeringIdentifier": "300_tokens"
            }
        ],
        "lifetime": null,
        "annual": null,
        "sixMonth": null,
        "threeMonth": null,
        "twoMonth": null,
        "monthly": null,
        "weekly": null,
        "attributes": {
            "tokens": 300,
            "price": "3.59 EUR",
            "tag": "Popular"
        }
    },
    {
        "identifier": "1000_tokens",
        "serverDescription": "1000_tokens",
        "metadata": {},
        "availablePackages": [
            {
                "identifier": "1000_tokens",
                "packageType": "CUSTOM",
                "product": {
                    "identifier": "1000_tokens",
                    "description": "1000_tokens",
                    "title": "1000_tokens (AI Girl: Virtual AI Girlfriend)",
                    "price": 8.99,
                    "priceString": "€8.99",
                    "currencyCode": "EUR",
                    "introPrice": null,
                    "discounts": null,
                    "productCategory": "NON_SUBSCRIPTION",
                    "productType": "CONSUMABLE",
                    "subscriptionPeriod": null,
                    "defaultOption": null,
                    "subscriptionOptions": null,
                    "presentedOfferingIdentifier": "1000_tokens"
                },
                "offeringIdentifier": "1000_tokens"
            }
        ],
        "lifetime": null,
        "annual": null,
        "sixMonth": null,
        "threeMonth": null,
        "twoMonth": null,
        "monthly": null,
        "weekly": null,
        "attributes": {
            "tokens": 1000,
            "price": "8.99 EUR",
            "tag": ""
        }
    },
    {
        "identifier": "3000_tokens",
        "serverDescription": "3000_tokens",
        "metadata": {},
        "availablePackages": [
            {
                "identifier": "3000_tokens",
                "packageType": "CUSTOM",
                "product": {
                    "identifier": "3000_tokens",
                    "description": "3000_tokens",
                    "title": "3000_tokens (AI Girl: Virtual AI Girlfriend)",
                    "price": 22.39,
                    "priceString": "€22.39",
                    "currencyCode": "EUR",
                    "introPrice": null,
                    "discounts": null,
                    "productCategory": "NON_SUBSCRIPTION",
                    "productType": "CONSUMABLE",
                    "subscriptionPeriod": null,
                    "defaultOption": null,
                    "subscriptionOptions": null,
                    "presentedOfferingIdentifier": "3000_tokens"
                },
                "offeringIdentifier": "3000_tokens"
            }
        ],
        "lifetime": null,
        "annual": null,
        "sixMonth": null,
        "threeMonth": null,
        "twoMonth": null,
        "monthly": null,
        "weekly": null,
        "attributes": {
            "tokens": 3000,
            "price": "22.39 EUR",
            "tag": ""
        }
    }
]
