export interface ProductTokensMap {
    [key: string]: number;
}

export const PRODUCT_TOKENS_MAP: ProductTokensMap = {
    '100_tokens': 100,
    '300_tokens': 300,
    '1000_tokens': 1000,
    '3000_tokens': 3000,
};

export interface TokensPackage {
    id: string; // You might want to change this to 'identifier' to match PurchasesPackage
    price: number;
    packageType: string; // new field, adjust the type as needed
    product: any; // new field, adjust the type as needed, e.g., some Product interface
    offeringIdentifier: string; // new field
}

export interface TokensProduct {
    identifier: string;
    packageType: string;
    availablePackages: TokensPackage[];
    attributes?: {
        tokens: number;
        price: string;
        tag: string;
        discount?: string;
    };
}
