import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AvatarConversation, ChatHistoryModel, MessageModel } from '@models/ChatHistory';
import { UserData } from '@models/User';
import { TranslateService } from '@ngx-translate/core';
import { ChatApiService } from '@services/api/apis/chat-api.service';
import { PromptsApiService } from '@services/api/apis/prompts-api.service';
import { SettingsService } from '@services/settings/settings.service';
import { SweetAlertsService } from '@services/sweet-alerts.service';
import Swal from 'sweetalert2';
import { UserDataService } from './firebase/user-data.service';
import { SuccessLottieService } from './sucess.service';

@Injectable()
export class ChatHistoryService {
    chatHistory: ChatHistoryModel | null = null;

    isLoadingChat: boolean = false;
    showDots: boolean = true;

    constructor(
        private settingsService: SettingsService,
        private userDataService: UserDataService,
        private sweetAlertsService: SweetAlertsService,
        private translate: TranslateService,
        private router: Router,
        private promptsApiService: PromptsApiService,
        private chatApiSv: ChatApiService,
        private successLottieService: SuccessLottieService,
    ) {}

    async initService() {
        console.log('initService');

        console.log('Cargando historial initService...');
        const user = await this.userDataService.getDataUser();
        if (user) {
            await this.loadHistory();
        } else {
            console.error('No user data available to load history.');
        }
    }

    async addMessageToHistory(message: any) {
        const chatHistory: MessageModel = {
            message: message.message,
            date: new Date(),
            role: message.role,
            type: message.type || '',
            url: message.url || '',
            hide: message.hide || false,
            valoration: message.valoration || false,
            price: message.price || '',
        };

        await this.saveChatHistory(chatHistory);
    }

    async saveChatHistory(history: MessageModel) {
        try {
            let user = await this.userDataService.checkUserDataLoaded();
            if (!user) {
                throw new Error('User data is not loaded');
            }

            if (!this.chatHistory) {
                this.chatHistory = this.createEmptyChatHistory(user.uid);
            }

            // Ensure avatarConversation contains an object for the user's selected avatar
            let avatarConversation = this.findOrCreateAvatarConversation(user.avatarSelected);

            avatarConversation.messages.push(history);

            await this.saveChatHistoryToApi(user.avatarSelected);
        } catch (error) {
            console.log('Error al guardar el historial:', error);
            throw new Error('Error al guardar el historial: ' + error);
        }
    }

    private async saveChatHistoryToApi(idAvatar: string): Promise<void> {
        if (!this.chatHistory) {
            throw new Error('Chat history is not initialized');
        }

        const body = {
            chatHistory: this.chatHistory.avatarConversation,
            idAvatar: idAvatar,
        };

        await this.chatApiSv.saveChatHistory(body);
    }

    private createEmptyChatHistory(uid: string): ChatHistoryModel {
        return {
            uid: uid,
            avatarConversation: {},
        };
    }

    private findOrCreateAvatarConversation(idAvatar: string): AvatarConversation {
        if (!this.chatHistory) {
            throw new Error('Chat history is not initialized');
        }

        let avatarConversation = this.chatHistory.avatarConversation[idAvatar];

        if (!avatarConversation) {
            avatarConversation = {
                messages: [],
            };
            this.chatHistory.avatarConversation[idAvatar] = avatarConversation;
        }

        return avatarConversation;
    }

    async loadHistory(): Promise<boolean> {
        try {
            console.log('Cargando historial...');

            const user = await this.userDataService.checkUserDataLoaded();
            if (!user) {
                throw new Error('User not authenticated');
            }

            const chats: ChatHistoryModel = await this.chatApiSv.getChatHistory();

            // Verifica si hay una conversación para el avatar actual
            let avatarConversation = null;
            if (chats && chats.avatarConversation && chats.avatarConversation[user.avatarSelected]) {
                avatarConversation = chats.avatarConversation[user.avatarSelected];
            }

            // Si no hay historial para el avatar actual, crea uno nuevo
            if (!chats || !avatarConversation || !avatarConversation?.messages || avatarConversation?.messages.length === 0) {
                console.log('Historial cargado desde Database vacío o no existe para el avatar actual');

                if (!chats || !chats.avatarConversation || !chats.avatarConversation[user.avatarSelected] || !chats.avatarConversation[user.avatarSelected].messages || chats.avatarConversation[user.avatarSelected].messages.length === 0) {
                    this.chatHistory = this.createEmptyChatHistory(user.uid);
                } else {
                    this.chatHistory = chats;
                }

                // Asegúrate de que avatarConversation esté inicializado correctamente
                if (!this.chatHistory.avatarConversation) {
                    this.chatHistory.avatarConversation = {};
                }

                avatarConversation = {
                    messages: [],
                };
                this.chatHistory.avatarConversation[user.avatarSelected] = avatarConversation;

                await this.createSystemMessage();
                await this.firstMessageAI();
            } else {
                console.log('Historial cargado desde Database');
                this.chatHistory = chats;
                this.scrollToBottom();
            }
            console.log('Historial final:', this.chatHistory);

            return true;
        } catch (e) {
            console.log('Error al cargar el historial:', e);
            throw new Error('Error al cargar el historial: ' + e);
        }
    }

    async createSystemMessage() {
        // ! Predefine Personality
        const initialPrompt: MessageModel = {
            message: await this.settingsService.setInitialPrompt(),
            date: new Date(),
            role: 'system',
        };
        this.saveChatHistory(initialPrompt as MessageModel);
    }

    async firstMessageAI() {
        // ! First Message
        const firstPrompt: MessageModel = {
            message: await this.promptsApiService.firstPromptAiApi(),
            date: new Date(),
            role: 'assistant',
            hide: false,
        };

        this.saveChatHistory(firstPrompt);
    }

    async removeHistory() {
        const user = await this.userDataService.checkUserDataLoaded();
        await this.chatApiSv
            .removeChatHistory(user.avatarSelected)
            .then(() => {
                console.log('Historial eliminado en Database exitosamente');
                return true;
            })
            .catch((error: any) => {
                console.log('Error al eliminar Historial en Database:', error);
                return false;
            });
    }

    clearHistory() {
        Swal.fire({
            title: 'Do you want clear history?',
            showCancelButton: true,
            confirmButtonText: 'Clear',
            cancelButtonText: `Cancel`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.successLottieService.loadAnimation();

                await this.removeHistory();
                await this.loadHistory();
            }
        });
    }

    async checkMessageLimit(): Promise<boolean> {
        let user: UserData = await this.userDataService.checkUserDataLoaded();

        if (user) {
            if (!user.premium) {
                await this.userDataService.checkResetDailyMessages();

                // Verifica si dailyMessages es un múltiplo de 10 más 5 (5, 15, 25, ...)
                if (user.dailyMessages % 10 === 5) {
                    console.log('Mostrar interstitial');
                    // this.UnityAdsSv.showInterstitial();
                }

                // Verifica si dailyMessages es un múltiplo de 30 (30, 60, 90, ...)
                if (user.dailyMessages % 30 === 0 && user.dailyMessages !== 0) {
                    // Redirecciona a la ruta /premium
                    this.router.navigate(['/dashboard/premium']);
                }

                // Verifica si se ha alcanzado el límite de mensajes
                if (user.dailyMessages >= this.settingsService.messagesDaily) {
                    this.sweetAlertsService.show('error', 'Oops...', this.translate.instant('MESSAGES.LIMIT_EXCEED'), 'dark');
                    console.log('Limite de mensajes excedido');
                    return false;
                }

                return true;
            }
            return true;
        }
        return false;
    }

    async updateChatHistory(updateType: string, newValue: string) {
        try {
            let user = await this.userDataService.checkUserDataLoaded();
            console.log(`Actualizando ${updateType} en el historial...`);

            if (this.chatHistory && this.chatHistory.avatarConversation) {
                const avatarConversation = this.chatHistory.avatarConversation[user.avatarSelected];
                if (avatarConversation) {
                    for (let message of avatarConversation.messages) {
                        if (message.role === 'system') {
                            let contentObj = JSON.parse(message.message);
                            if (contentObj.instructions) {
                                switch (updateType) {
                                    case 'name':
                                        contentObj.instructions.assistant.name = newValue;
                                        console.log('Nombre actualizado en el historial', newValue);
                                        break;
                                    case 'nameUser':
                                        contentObj.instructions.nameUser = newValue;
                                        console.log('Nombre de usuario actualizado en el historial', newValue);
                                        break;
                                    case 'language':
                                        contentObj.instructions.inputLanguage.language = newValue;
                                        console.log('Idioma actualizado en el historial', newValue);
                                        break;
                                    default:
                                        console.log('Tipo de actualización no soportado');
                                        return false;
                                }
                                message.message = JSON.stringify(contentObj);
                                break; // Suponiendo que solo hay un mensaje 'system' que deseas actualizar
                            }
                        }
                    }
                }

                return this.chatApiSv
                    .saveChatHistory({
                        chatHistory: this.chatHistory.avatarConversation,
                        idAvatar: user.avatarSelected,
                    })
                    .then(() => console.log(`${updateType} actualizado en Database exitosamente`))
                    .catch((error: any) => {
                        console.log(`Error al actualizar el ${updateType} en Database:`, error);
                        throw new Error(`Error al actualizar el ${updateType} en el historial: ` + error);
                    });
            }
        } catch (error) {
            console.log(`Error al actualizar el ${updateType} en el historial:`, error);
            throw new Error(`Error al actualizar el ${updateType} en el historial: ` + error);
        }
    }

    scrollToBottom(): void {
        setTimeout(() => {
            const messageContainerElement = document.querySelector('#chat-history');
            if (messageContainerElement) {
                messageContainerElement.scrollTop = messageContainerElement.scrollHeight;
            }
        }, 100);
    }
}
