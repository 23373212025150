import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Network } from '@capacitor/network';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NetworkService {
    private networkStatus = new BehaviorSubject<boolean | null>(null);
    networkStatus$ = this.networkStatus.asObservable();

    private readonly platformId = inject(PLATFORM_ID);

    constructor() {}

    initNetwork() {
        this.getCurrentNetworkStatus();
        if (isPlatformBrowser(this.platformId)) {
            Network.addListener('networkStatusChange', (status) => {
                this.updateNetworkStatus(status.connected);
            });
        }
    }

    async checkNetwork() {
        if (isPlatformBrowser(this.platformId)) {
            Network.addListener('networkStatusChange', (status) => {
                console.log('Network status changed', status);
                const isConnected = status.connected;

                this.networkStatus.next(isConnected);
            });
        }
    }

    async logCurrentNetworkStatus() {
        if (isPlatformBrowser(this.platformId)) {
            const status = await Network.getStatus();

            console.log('Current status:', status);

            this.updateNetworkStatus(status.connected);
        }
    }

    getCurrentNetworkStatus() {
        if (isPlatformBrowser(this.platformId)) {
            return Network.getStatus().then((status) => {
                this.updateNetworkStatus(status.connected);
            });
        }
        return;
    }

    private updateNetworkStatus(isConnected: boolean) {
        this.networkStatus.next(isConnected);
    }
}
