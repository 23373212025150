import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';

@Injectable()
export class TransactionsApiService {
    constructor(private apiService: ApiService) {}

    // Save transaction to database
    saveTransactionToDatabase(body: any) {
        return this.apiService.postRequest<any>('transactions/add', body);
    }
}
