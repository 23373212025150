import { Injectable } from '@angular/core';
import { RateApp } from 'capacitor-rate-app';
import { ToolsService } from './settings/tools.service';

@Injectable()
export class RateAppService {
    counter = 0;

    constructor(private toolsService: ToolsService) {}

    async rateApp() {
        console.log('Initializing rateApp...');

        if (this.counter > 0) return;

        // Check if the platform is not web
        const platform = await this.toolsService.getPlatform();
        if (platform === 'web') {
            console.log('rateApp is not supported on web platform');
            return;
        }

        setTimeout(async () => {
            console.log('Show rateApp...');
            try {
                await RateApp.requestReview();
            } catch (error) {
                console.error('Error requesting app review:', error);
            }

            this.counter++;
        }, 120000);
    }
}
