import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class AppVersionService {
    version: string = '0';

    constructor(private platform: Platform) {}

    async getAppVersion(): Promise<string> {
        if (this.platform.is('capacitor')) {
            const info = await App.getInfo();
            return info.version;
        } else {
            return '-';
        }
    }
}
