import { Injectable } from '@angular/core';
import { MessageModel } from '@models/ChatHistory';
import { TranslateService } from '@ngx-translate/core';
import { ChatHistoryService } from '@services/chat-history.service';
import { ChatLLM } from '@services/ia/chat-llm.service';
import { LoadingService } from '@services/loading.service';
import { SettingsService } from '@services/settings/settings.service';
import { SweetAlertsService } from '@services/sweet-alerts.service';
import { BehaviorSubject } from 'rxjs';
import { UserDataService } from './firebase/user-data.service';

@Injectable()
export class MessagesService {
    message: MessageModel = { message: '', date: new Date(), role: '', type: '', url: '', hide: false };
    chatVoice = false;
    holdingVoice: boolean = false;
    isWriting = false;
    timeToSendMsg: number = Date.now();

    private isWritingSource = new BehaviorSubject(false);

    constructor(
        private chatHistoryService: ChatHistoryService,
        private settingsService: SettingsService,
        private userDataService: UserDataService,
        private sweetAlertsService: SweetAlertsService,
        private translateService: TranslateService,
        private chatllmService: ChatLLM,
        private loadingService: LoadingService,
    ) {}

    async toggleMicrophone() {
        let user = await this.userDataService.checkUserDataLoaded();
        if (user.premium) {
            this.chatVoice = !this.chatVoice;
        } else {
            this.sweetAlertsService.show('info', 'Oops...', this.translateService.instant('MESSAGES.NEED_PREMIUM'), 'dark');
        }
    }

    async updateMessageSystemPremium() {
        let message = await this.settingsService.getMsgSystemPremium();
        this.message = { message, date: new Date(), role: 'system' };
        this.chatHistoryService.addMessageToHistory(this.message);
    }

    setWritingStatus(status: boolean) {
        this.isWritingSource.next(status);
    }

    async updateMessageUser(message: string, typeMessage: string, _type: string, _url: string) {
        if (typeMessage === 'chat') {
            if (_type === 'image') {
                this.message = { message, date: new Date(), role: 'user', type: 'image', url: _url };
            } else {
                this.message = { message, date: new Date(), role: 'user' };
            }
        } else if (typeMessage === 'call') {
            this.message = { message, date: new Date(), role: 'user', hide: true };
        }
        await this.chatHistoryService.addMessageToHistory(this.message);
    }

    async sendMessage(message: string, _type?: string, _url?: string) {
        if (this.chatllmService.isRequestInProgress) {
            console.warn('Ya hay una petición en proceso. Por favor, espera a que se complete.');
            return;
        }

        if (!(await this.chatHistoryService.checkMessageLimit())) return;
        console.log('sendMessage');
        this.chatHistoryService.scrollToBottom();
        this.chatHistoryService.isLoadingChat = true;
        this.chatllmService.timeOfLastMessage = Date.now(); // Pone el contador a 0 para crear delay entre mensajes forzados por ejemplo para los no premium

        if (_type === 'image' && _url !== undefined) {
            await this.updateMessageUser(message, 'chat', 'image', _url);
        } else {
            await this.updateMessageUser(message, 'chat', '', '');
        }

        this.timeToSendMsg = Date.now();

        try {
            await this.chatllmService.sendChatGpt('chat');
        } catch (err) {
            console.log(err);
            this.loadingService.loadingHide();
        }
    }
}
