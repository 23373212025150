import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { LOG_LEVEL, Purchases } from '@revenuecat/purchases-capacitor';
import { UserDataService } from '@services/firebase/user-data.service';
import { ToolsService } from '@services/settings/tools.service';
import TestRevenuecat from '../../data/testRevenuecat.json';

@Injectable()
export class RevenuecatService {
    products: any = [];
    isRevenueCatConfigLoaded = false;

    constructor(
        private userDataService: UserDataService,
        private toolsService: ToolsService,
    ) {}

    async initRevenueCatConfig() {
        console.log('initRevenueCatConfig');
        const platform = await this.toolsService.getPlatform();

        //! Version Test en la web
        if (!environment.production && platform === 'web' && TestRevenuecat) {
            this.products = TestRevenuecat;
            return true;
        } else {
            if (platform === 'web') return false;

            try {
                let user = await this.userDataService.checkUserDataLoaded();

                let isIos = await this.toolsService.detectDevice();
                let apiKeyRevenuecat = isIos ? 'appl_tJzBGHMdgDbOglQAQtQVstkakcS' : 'goog_SxmcmtNfoJLQPZDOgldBvXXLPBg';

                if (this.isRevenueCatConfigLoaded === false) {
                    // Configurando RevenueCat.
                    await Purchases.configure({
                        apiKey: apiKeyRevenuecat,
                        appUserID: user.uid,
                    });
                }
                // Estableciendo los logs para RevenueCat.
                this.logsRevenueCat();

                // Cargando las ofertas y productos disponibles.
                let offerings = await this.loadOfferings();

                if (!offerings) {
                    console.log('Advertencia: No se encontraron ofertas en RevenueCat. La aplicación continuará sin ellas.');
                } else {
                    this.products = Object.values(await this.loadProducts(offerings));

                    if (!this.products || this.products.length === 0) {
                        console.warn('Advertencia: No se encontraron productos en RevenueCat. La aplicación continuará sin ellos.');
                    }
                }

                this.isRevenueCatConfigLoaded = true;

                return true;
            } catch (error) {
                console.log('Error initializing RevenueCat Config', error);
                this.isRevenueCatConfigLoaded = false;
                return false;
            }
        }
    }

    async loadOfferings() {
        let offerings = await Purchases.getOfferings();

        if (offerings.all !== null) {
            return offerings.all;
        }

        return null;
    }

    async loadProducts(products: any) {
        return products;
    }

    async logsRevenueCat() {
        Purchases.setLogHandler((message) => {
            console.log('RevenueCat:', message);
        });

        Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
    }

    getCurrencySymbol(currency: string) {
        const currencySymbol: { [key: string]: string } = {
            USD: '$', // US Dollar
            EUR: '€', // Euro
            CRC: '₡', // Costa Rican Colon
            GBP: '£', // British Pound Sterling
            ILS: '₪', // Israeli New Sheqel
            INR: '₹', // Indian Rupee
            JPY: '¥', // Japanese Yen
            KRW: '₩', // South Korean Won
            NGN: '₦', // Nigerian Naira
            PHP: '₱', // Philippine Peso
            PLN: 'zł', // Polish Zloty
            PYG: '₲', // Paraguayan Guarani
            THB: '฿', // Thai Baht
            UAH: '₴', // Ukrainian Hryvnia
            VND: '₫', // Vietnamese Dong
        };

        return currencySymbol[currency] || currency;
    }
}
