import { Injectable } from '@angular/core';
import { ApiService } from '@services/api/api.service';
import { ChatHistoryModel } from '../../../models/ChatHistory';
@Injectable()
export class ChatApiService {
    constructor(private apiService: ApiService) {}

    async saveChatHistory(chatHistory: any) {
        return this.apiService.postRequest('chats/history/saveChat', chatHistory);
    }

    async getChatHistory(): Promise<ChatHistoryModel> {
        console.log('getChatHistory');
        const a: ChatHistoryModel = await this.apiService.getRequest('chats/history/get');
        console.log('a', a);
        return a;
    }

    async removeChatHistory(idAvatar: string) {
        return this.apiService.getRequest(`chats/history/remove/${idAvatar}`);
    }
}
