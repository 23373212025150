import { Injectable } from '@angular/core';
import { PurchasesData } from '@models/Purchases';
import { PurchasesServiceApi } from '@services/api/apis/purchases-api.service';
import { AuthenticationService } from '@services/firebase/authentication.service';

@Injectable()
export class PurchasesService {
    constructor(
        private authenticationService: AuthenticationService,
        private purchasesServiceApi: PurchasesServiceApi,
    ) {}

    async savePurchase(purchase: PurchasesData) {
        console.log('Purchase saved:', purchase);
        if (!this.authenticationService.isAuthenticated()) return null;
        return await this.purchasesServiceApi.savePurchaseApi(purchase);
    }
}
