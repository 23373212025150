import { Component } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'coming-soon',
    templateUrl: './coming-soon.component.html',
    styleUrls: ['./coming-soon.component.scss'],
    standalone: true,
    imports: [SharedModule],
})
export class ComingSoonComponent {
    visible: boolean = false;
    timeoutRef: any;

    constructor(private messageService: MessageService) {}

    showConfirm() {
        console.log('showConfirm');
        if (!this.visible) {
            this.messageService.add({ key: 'confirm', sticky: true, severity: 'custom', summary: 'Coming Soon!' });
            this.visible = true;
            this.timeoutRef = setTimeout(() => this.onAutoClose(), 4000);
        }
    }

    onAutoClose() {
        this.messageService.clear('confirm');
        this.visible = false;
    }

    onClose() {
        clearTimeout(this.timeoutRef);
        this.messageService.clear('confirm');
        this.visible = false;
    }
}
