import { Injectable } from '@angular/core';
import { PurchasesData } from '@models/Purchases';
import { PRODUCT_TOKENS_MAP, ProductTokensMap, TokensProduct } from '@models/TokensProduct';
import { Transactions } from '@models/Transactions';
import { PACKAGE_TYPE, PURCHASES_ERROR_CODE, Purchases, PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { TransactionsApiService } from '@services/api/apis/transactions-api.service';
import { UserDataService } from '@services/firebase/user-data.service';
import { PurchasesService } from './purchases.service';

@Injectable()
export class TokensService {
    private productTokensMap: ProductTokensMap = PRODUCT_TOKENS_MAP;

    constructor(
        private userDataService: UserDataService,
        private transactionsService: TransactionsApiService,
        private purchasesService: PurchasesService,
    ) {}

    getTokensFromProductId(productId: string): number {
        return this.productTokensMap[productId] || 0; // Asegúrate de manejar adecuadamente si el productId no existe.
    }

    async purchaseTokens(product: TokensProduct): Promise<boolean> {
        try {
            const tokenPackage = product.availablePackages[0];
            const purchasesPackage: PurchasesPackage = {
                identifier: product.identifier,
                packageType: product.availablePackages[0].packageType as PACKAGE_TYPE,
                product: tokenPackage.product,
                offeringIdentifier: tokenPackage.offeringIdentifier,
                presentedOfferingContext: {
                    offeringIdentifier: tokenPackage.offeringIdentifier,
                    placementIdentifier: null,
                    targetingContext: null,
                },
            };

            const purchaseResult = await Purchases.purchasePackage({ aPackage: purchasesPackage });

            if (typeof purchaseResult.customerInfo.entitlements.active['my_entitlement_identifier'] !== 'undefined') {
                console.log('UNDEFINED Unlock that great "TOKENS" content', purchaseResult);
                throw new Error('No se ha podido realizar la compra');
            } else {
                console.log('OK BUY TOKENS', purchaseResult);

                try {
                    await this.addTokensProduct(product);

                    return true;
                } catch (error) {
                    console.log('Error al guardar los datos:', error);
                    // Si hay un error, se cancela la compra.
                    throw new Error('No se ha podido realizar la compra');
                }
            }
        } catch (error: any) {
            if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
                console.log('Purchase cancelled', error);
            } else {
                console.log('Error making purchase', error);
            }

            return false;
        }
    }

    async addTokensProduct(product: TokensProduct) {
        let user = await this.userDataService.checkUserDataLoaded();

        // Aquí utilizamos el método getTokensFromProductId para obtener el número de tokens
        const tokensToAdd = this.getTokensFromProductId(product.identifier);

        if (tokensToAdd == 0) {
            throw new Error('No se ha podido realizar la compra');
        }

        // add tokens to existing tokens
        const tokens = user.tokens + tokensToAdd;

        const userDataTokens: Transactions = {
            description: 'Add Tokens By Purchase',
            tokensNow: user.tokens,
            uid: user.uid,
            tokensEarn: tokensToAdd,
            tokensLost: 0,
            productId: product.identifier,
            date: new Date(),
        };

        const userData = {
            tokens: tokens,
        };

        const purchase: PurchasesData = {
            product: product.identifier,
            description: 'Buy Tokens',
            uid: user.uid,
            date: new Date(),
            price: product.availablePackages[0].product.price,
        };

        await this.purchasesService.savePurchase(purchase);
        await this.transactionsService.saveTransactionToDatabase(userDataTokens);

        return await this.userDataService
            .updateDataUser(userData)
            .then(() => {
                if (!user) return false;

                console.log('Tokens guardado en Database:', userData);
                user.tokens = tokens;
                return true;
            })
            .catch((error: any) => {
                console.log('Error al guardar TOKENS en colección users:', error, user);
                return false;
            });
    }

    async addTokens(tokensToAdd: number) {
        let user = await this.userDataService.checkUserDataLoaded();

        // add tokens to existing tokens
        const tokens = user.tokens + tokensToAdd;

        const userData: Transactions = {
            description: 'Add Tokens free for Premium purchase',
            tokensNow: user.tokens,
            uid: user.uid,
            tokensEarn: tokensToAdd,
            tokensLost: 0,
            productId: '',
            date: new Date(),
        };

        this.transactionsService.saveTransactionToDatabase(userData);

        // Objeto solo con las token actualizadas para guardar en la tabla 'users'
        const userDataForUsersTable = {
            tokens: tokens,
        };

        return await this.userDataService
            .updateDataUser(userDataForUsersTable)
            .then(async () => {
                let user = await this.userDataService.checkUserDataLoaded();
                console.log('Tokens guardado en Database:', userData);
                user.tokens = tokens;

                return true;
            })
            .catch((error: any) => {
                console.log('Error al guardar Tokens en colección users:', error, user);
                return false;
            });
    }

    async decreaseTokens(tokens: number) {
        let user = await this.userDataService.checkUserDataLoaded();

        const remainingTokens = user.tokens - tokens;

        const userDataTokens: Transactions = {
            description: 'Decrease Tokens',
            tokensNow: user.tokens,
            uid: user.uid,
            tokensEarn: 0,
            tokensLost: tokens,
            productId: '',
            date: new Date(),
        };

        this.transactionsService.saveTransactionToDatabase(userDataTokens);

        const userData = {
            tokens: remainingTokens,
            uid: user.uid,
        };

        return await this.userDataService
            .updateDataUser(userData)
            .then(async () => {
                let user = await this.userDataService.checkUserDataLoaded();

                console.log('Tokens guardado en Database:', userData);
                user.tokens = remainingTokens;
                return true;
            })
            .catch((error: any) => {
                console.log('Error al guardar TOKENS en colección users:', error, user);
                return false;
            });
    }

    async buyAvatar(avatar: string, price: number) {
        let user = await this.userDataService.checkUserDataLoaded();

        // Chequear si el usuario tiene suficientes tokens para comprar el avatar
        if (user.tokens < price) {
            console.log('No hay tokens suficientes para realizar esta compra.');
            throw new Error('No have enough tokens');
        }

        const purchasedAvatars = user.purchasedAvatars || []; // Asegurarse de que exista el array
        purchasedAvatars.push(avatar);

        // Restar las tokens del precio del avatar
        const remainingTokens = user.tokens - price;

        const userDataTokens: Transactions = {
            description: 'Buy Avatar',
            tokensNow: user.tokens,
            uid: user.uid,
            tokensEarn: 0,
            tokensLost: 0,
            productId: '',
            date: new Date(),
        };

        this.transactionsService.saveTransactionToDatabase(userDataTokens);

        const userData = {
            purchasedAvatars: purchasedAvatars,
            tokens: remainingTokens,
        };

        return await this.userDataService
            .updateDataUser(userData)
            .then(async () => {
                let user = await this.userDataService.checkUserDataLoaded();

                console.log('Avatar comprado en Database:', userData);
                user.purchasedAvatars = purchasedAvatars;
                user.tokens = remainingTokens; // Actualizar tokens en el servicio
                return true;
            })
            .catch((error: any) => {
                console.log('Error al guardar AVATAR en colección users:', error, user);
                throw new Error('Error to buy avatar');
            });
    }
}
