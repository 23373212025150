<p-toast position="top-center" key="confirm" (onClose)="onClose()" [baseZIndex]="5000000">
    <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
        <section class="flex p-3 gap-3 w-full shadow-2 section" style="border-radius: 10px; position: relative">
            <i class="pi pi-times text-white" style="position: absolute; top: 10px; right: 10px; cursor: pointer" (click)="closeFn($event)"></i>

            <div class="flex flex-column gap-3 w-full">
                <p class="m-0 font-semibold text-base text-white">
                    {{ message.summary }}
                </p>
                <p class="m-0 text-base text-700">
                    <label class="text-right text-4xl text-white"> ❤️ </label>
                </p>
            </div>
        </section>
    </ng-template>
</p-toast>
