import { Injectable } from '@angular/core';
import { PremiumPurchase } from '@models/Premium';
import { PurchasesData } from '@models/Purchases';
import { SubscriptionProduct } from '@models/SubscriptionProduct';
import { TranslateService } from '@ngx-translate/core';
import { PACKAGE_TYPE, PURCHASES_ERROR_CODE, Purchases, PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { PremiumApiService } from '@services/api/apis/premium-api.service';
import { UserDataService } from '@services/firebase/user-data.service';
import { LoadingService } from '@services/loading.service';
import { TokensService } from '@services/purchases/tokens.service';
import { SweetAlertsService } from '@services/sweet-alerts.service';
import { PurchasesService } from './purchases.service';

@Injectable()
export class PremiumService {
    constructor(
        private userDataService: UserDataService,
        private tokensService: TokensService,
        private loadingService: LoadingService,
        private sweetAlertsService: SweetAlertsService,
        private translate: TranslateService,
        private premiumApiService: PremiumApiService,
        private purchasesService: PurchasesService,
    ) {}

    async purchaseSubscription(expireDate: Date, subscriptionSelected: SubscriptionProduct): Promise<boolean> {
        let user = await this.userDataService.checkUserDataLoaded();
        if (!subscriptionSelected) {
            console.log('The subscriptionSelected object is not valid');
        }

        const subscriptionPackage = subscriptionSelected.availablePackages[0];

        const planSubscribeSelected: PurchasesPackage = {
            identifier: subscriptionPackage.identifier,
            packageType: subscriptionPackage.packageType as PACKAGE_TYPE,
            product: subscriptionPackage.product,
            offeringIdentifier: subscriptionPackage.offeringIdentifier,
            presentedOfferingContext: {
                offeringIdentifier: subscriptionPackage.offeringIdentifier,
                placementIdentifier: null,
                targetingContext: null,
            },
        };

        if (!planSubscribeSelected) {
            console.log('No se ha podido obtener el plan seleccionado');
            return false;
        }

        try {
            const purchaseResult = await Purchases.purchasePackage({ aPackage: planSubscribeSelected });
            if (typeof purchaseResult.customerInfo.entitlements.active['my_entitlement_identifier'] !== 'undefined') {
                console.log('UNDEFINED Unlock that great "pro" content', purchaseResult);
                throw new Error('No se ha podido realizar la compra');
            } else {
                console.log('OK Unlock that great "pro" content', purchaseResult);
                const purchaseData: PremiumPurchase = {
                    datePurchase: new Date(),
                    expireDate: expireDate,
                    productId: subscriptionSelected.identifier,
                    price: planSubscribeSelected.product.price,
                };

                const purchase: PurchasesData = {
                    product: planSubscribeSelected.product.identifier,
                    description: 'Premium subscription',
                    uid: user.uid,
                    date: new Date(),
                    price: planSubscribeSelected.product.price,
                };

                try {
                    await this.purchasesService.savePurchase(purchase);

                    // Añadir información de la suscripción en la colección premium.
                    await this.premiumApiService.addPremiumPurchaseHistory(purchaseData);

                    // Actualizar el estado premium del usuario en la colección users.
                    await this.userDataService.updateDataUser({ premium: true });

                    // Add tokens free
                    await this.tokensService.addTokens(100);

                    console.log('Premium y historial de compra actualizado en Database:', purchaseData);

                    user.premium = true;
                    return true;
                } catch (error) {
                    console.log('Error al guardar los datos:', error);

                    // Si hay un error, se cancela la compra.
                    throw new Error('No se ha podido realizar la compra');
                }
            }
        } catch (error: any) {
            if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
                console.log('Purchase cancelled', error);
            } else {
                console.log('Error making purchase', error);
            }

            return false;
        }
    }

    getExpirationDate(productId: SubscriptionProduct): Date {
        let planSubscribeSelected;
        if (productId.identifier == 'monthly_subscription') {
            planSubscribeSelected = 'monthly_subscription';
        }
        if (productId.identifier == 'annual_subscription') {
            planSubscribeSelected = 'annual_subscription';
        }

        const now = new Date();
        switch (planSubscribeSelected) {
            case 'annual_subscription':
                now.setFullYear(now.getFullYear() + 1);
                break;
            case 'monthly_subscription':
                now.setMonth(now.getMonth() + 1);
                break;
            default:
                throw new Error('Invalid product ID');
        }

        return now;
    }

    async checkPremium(): Promise<boolean> {
        console.log('checkPremium');
        let user = await this.userDataService.checkUserDataLoaded();
        if (!user.premium) {
            return true;
        }

        try {
            await this.premiumApiService.checkUserHasPremium();

            console.log('El usuario tiene premium');

            this.userDataService.loadUserData();

            return true;
        } catch (error) {
            console.log('Error al obtener los datos:', error);
            throw new Error('Error al obtener los datos Premium:' + error);
        }
    }

    async restoreSubscription() {
        let user = await this.userDataService.checkUserDataLoaded();
        this.loadingService.loadingShow();

        setTimeout(async () => {
            if (user!.premium) {
                console.log('Restauración exitosa. Suscripción previa detectada.');

                if (!user) {
                    console.log('Error: No user data available.');
                    return;
                }

                this.loadingService.loadingHide();

                this.sweetAlertsService.show('success', this.translate.instant('SHARED.SUCCESS'), this.translate.instant('PURCHASES.SUCCESS_RESTORE_SUBSCRIPTION'), 'dark');
                return false;
            } else {
                console.log('No se ha detectado ninguna versión antigua de suscripción.');

                this.loadingService.loadingHide();

                this.sweetAlertsService.show('error', this.translate.instant('SHARED.ERROR'), this.translate.instant('PURCHASES.ERROR_RESTORE_SUBSCRIPTION'), 'dark');

                return true;
            }
        }, 3000);
    }
}
