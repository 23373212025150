import { Injectable } from '@angular/core';
import { UserData } from '@models/User';
import { TextToSpeechAPIService } from '@services/api/apis/text-to-speech-api.service';
import { UserDataService } from '@services/firebase/user-data.service';
import { TokensService } from '@services/purchases/tokens.service';
import { SuccessLottieService } from '@services/sucess.service';

@Injectable()
export class TextToSpeechService {
    audio = new Audio();

    constructor(
        private textToSpeechApiService: TextToSpeechAPIService,
        private userDataService: UserDataService,
        private tokensService: TokensService,
        private successLottieService: SuccessLottieService,
    ) {}

    async getVoices() {
        return await this.textToSpeechApiService.getVoicesApi();
    }

    async playMessageAudio(messageAI: string, pitch: number, speakingRate: number, voiceCall: boolean) {
        // ? Get audio
        try {
            let user: UserData = await this.userDataService.checkUserDataLoaded();

            let voiceType = user.avatars[user.avatarSelected].voice;
            const audioBlob = await this.textToSpeechApiService.textToSpeech(messageAI, 'fs82g0ORiR3VaYUXwdDs', pitch, speakingRate, voiceType, voiceCall);
            await this.loadAudio(audioBlob);
            this.audio.play();
        } catch (error) {
            console.log(error);
            return 'Error playMessageAudio:' + error;
        }

        return 'OK';
    }

    async loadAudio(audioBuffer: { type: string; data: number[] }): Promise<void> {
        return new Promise((resolve, reject) => {
            // Convert buffer array to Blob
            const audioBlob = new Blob([new Uint8Array(audioBuffer.data)], { type: 'audio/mpeg' }); // Assuming that the audio is in mp3 format.

            const audioUrl = URL.createObjectURL(audioBlob);
            this.audio.src = audioUrl;
            this.audio.onloadeddata = () => {
                resolve();
            };
            this.audio.onerror = (error) => {
                reject(error);
            };
            this.audio.load();
        });
    }

    async getAllowSpeechText() {
        let user = await this.userDataService.checkUserDataLoaded();

        if (user && user.avatars[user.avatarSelected].speakEnable) {
            if (user.avatars[user.avatarSelected].voice === 'default' && user.tokens > 2) {
                await this.tokensService.decreaseTokens(2);
                return true;
            }

            if (user.avatars[user.avatarSelected].voice === 'pro' && user.tokens > 20) {
                await this.tokensService.decreaseTokens(20);
                return true;
            }

            return false;
        } else {
            return false;
        }
    }

    async saveVoice(voice: string) {
        if (voice !== 'default' && voice !== 'pro') return;
        let user = await this.userDataService.checkUserDataLoaded();

        const newData = {
            voice: voice,
        };

        await this.userDataService
            .updateDataUser(newData)
            .then(async () => {
                user.avatars[user.avatarSelected].voice = voice;
                this.successLottieService.loadAnimation();

                return true;
            })
            .catch((error: any) => {
                console.log('Error al guardar voice en Database:', error);
                return false;
            });
    }
}
