import { Injectable } from '@angular/core';
import languages from '@data/languages.json';
import { LanguagesAi, LanguagesApp } from '@models/Languages';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesApiService } from '@services/api/apis/languages-api.service';
import { UserDataService } from '@services/firebase/user-data.service';
import { DeepgramTranscriber } from '@vapi-ai/web/dist/api';
@Injectable()
export class LanguageService {
    constructor(
        private userDataService: UserDataService,
        private translateService: TranslateService,
        private languagesApiService: LanguagesApiService,
    ) {}

    async setLanguageDefaultApp(): Promise<void> {
        this.translateService.setDefaultLang('en');

        const userData = await this.userDataService.getDataUser();
        if (userData) {
            const languageCode = this.getLanguageCodeByName(userData.languageApp);
            if (languageCode) {
                this.translateService.use(languageCode);
            }
        }
    }

    async setLanguageApp(languageApp: string): Promise<void> {
        const userData = await this.userDataService.getDataUser();
        if (!userData || !languageApp) return;

        const userLanguageCode = this.getLanguageCodeByName(languageApp);
        if (userLanguageCode) {
            this.translateService.use(userLanguageCode);
        }
    }

    getLanguagesAi(): LanguagesAi {
        return { languages: languages.languagesAi };
    }

    getLanguagesAPP(): LanguagesApp {
        return { languagesApp: languages.languagesApp };
    }

    async changeLanguageUserApp(languageApp: string): Promise<boolean> {
        await this.userDataService.getDataUser();
        return this.languagesApiService
            .changeLanguageUserAppApi(languageApp)
            .then(async () => {
                let user = await this.userDataService.checkUserDataLoaded();
                user.languageApp = languageApp;
                await this.setLanguageApp(languageApp);
                return true;
            })
            .catch((error: any) => {
                console.log('Error updating document languageAPP: ', error);
                throw error;
            });
    }

    getLanguageByCode(code: string): string | undefined {
        return this.getLanguagesAPP().languagesApp.find((l) => l.code === code)?.name;
    }

    getLanguageCodeByName(languageName: string): string | undefined {
        return languages.languagesApp.find((l) => l.name === languageName)?.code;
    }

    async getLanguageVapi(language: string): Promise<DeepgramTranscriber['language']> {
        const languageCodeVapi = await this.getLanguageCodeByName(language);
        const supportedLanguages: DeepgramTranscriber['language'][] = ['en', 'es', 'bg', 'zh', 'cs', 'da', 'nl', 'fr', 'de', 'el', 'hi', 'id', 'it', 'ja', 'ko', 'no', 'pl', 'pt', 'ro', 'ru', 'sv', 'tr', 'uk', 'da-DK', 'de-CH', 'en-US', 'es-419', 'fr-CA', 'sv-SE', 'zh-TW'];
        if (supportedLanguages.includes(languageCodeVapi as DeepgramTranscriber['language'])) {
            return languageCodeVapi as DeepgramTranscriber['language'];
        }
        return 'en'; // default language
    }
}
