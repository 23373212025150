import { Injectable } from '@angular/core';
import { Relationship } from '@models/Avatars';
import { MessageModel } from '@models/ChatHistory';
import { UserData } from '@models/User';
import { TranslateService } from '@ngx-translate/core';
import { ChatHistoryService } from '@services/chat-history.service';
import { SettingsService } from '@services/settings/settings.service';
import { SweetAlertsService } from '@services/sweet-alerts.service';
import { AvatarsApiService } from './api/apis/avatars-api.service';
import { PromptsApiService } from './api/apis/prompts-api.service';
import { AuthenticationService } from './firebase/authentication.service';
import { UserDataService } from './firebase/user-data.service';
import { LoadingService } from './loading.service';
import { SuccessLottieService } from './sucess.service';

@Injectable()
export class AvatarsService {
    constructor(
        private settingsService: SettingsService,
        private chatHistoryService: ChatHistoryService,
        private sweetAlertsService: SweetAlertsService,
        private userDataService: UserDataService,
        private promptsApiService: PromptsApiService,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private avatarApiService: AvatarsApiService,
        private loadingService: LoadingService,
        private successLottieService: SuccessLottieService,
    ) {}

    async getAvatars() {
        const avatars = await this.avatarApiService.getAvatarsApi();
        return avatars;
    }

    async updatePersonality() {
        let user = await this.userDataService.checkUserDataLoaded();

        let body = {
            pessimistic: user.avatars[user.avatarSelected].personality.pessimistic,
            optimistic: user.avatars[user.avatarSelected].personality.optimistic,
            cautious: user.avatars[user.avatarSelected].personality.cautious,
            curious: user.avatars[user.avatarSelected].personality.curious,
            tolerant: user.avatars[user.avatarSelected].personality.tolerant,
            judgemental: user.avatars[user.avatarSelected].personality.judgemental,
        };
        try {
            await this.avatarApiService.updateAvatarApi(user.avatarSelected, body);
            await this.promptsApiService.updatePersonalityPromptApi(body);

            const chatHistory: MessageModel = {
                message: await this.settingsService.setInitialPrompt(),
                date: new Date(),
                role: 'system',
            };
            await this.chatHistoryService.saveChatHistory(chatHistory);

            await this.userDataService.checkAndUpdateUserData();

            // Recupera y actualiza el historial de chat
            await this.chatHistoryService.loadHistory();

            // this.successLottieService.loadAnimation();
        } catch (e) {
            console.error('Error updating personality:', e);
            this.sweetAlertsService.show('error', this.translateService.instant('SHARED.ERROR'), this.translateService.instant('PERSONALITY.ERROR_UPDATE'), 'dark');

            throw new Error('Error save personality account: ' + e);
        }
    }

    async changeNameAvatar(newName: string) {
        let user: UserData = await this.userDataService.checkUserDataLoaded();

        const newData = {
            nameAssistant: newName,
        };

        await this.userDataService
            .updateDataUser(newData)
            .then(async () => {
                user.avatars[user.avatarSelected].name = newName;
                await this.chatHistoryService.updateChatHistory('name', user.nameUser);
                await this.updateNameAvatarInChat();
                return true;
            })
            .catch((error: any) => {
                console.error('Error al guardar el Name en Database:', error);
                return false;
            });
    }

    async updateNameAvatarInChat() {
        const chatHistory: MessageModel = {
            message: await this.settingsService.changeNameAvatar(),
            date: new Date(),
            role: 'system',
        };

        this.chatHistoryService.saveChatHistory(chatHistory);
    }

    async changeAvatar(newAvatarId: string) {
        try {
            this.loadingService.loadingShowSpinner();
            await this.userDataService.checkUserDataLoaded();

            this.avatarApiService.updateAvatarApi(newAvatarId, {});

            // this.successLottieService.loadAnimation();

            await this.chatHistoryService.loadHistory();
            await this.userDataService.checkAndUpdateUserData();
            this.loadingService.loadingHideSpinner();
        } catch (e) {
            console.error('Error al guardar el Avatar en Database:', e);
            this.sweetAlertsService.show('error', '', 'Error changing avatar', 'dark');
            this.loadingService.loadingHideSpinner();
        }
    }

    async changeRelationShipAvatar(relation: Relationship) {
        let user = await this.userDataService.checkUserDataLoaded();

        const newData = {
            relationShip: relation,
        };

        this.userDataService
            .updateDataUser(newData)
            .then(async () => {
                if (!user) return;
                user.avatars[user.avatarSelected].relationShip = relation;

                await this.updateRelationShipInChat();
                this.successLottieService.loadAnimation();

                return true;
            })
            .catch((e: any) => {
                console.error('Error al guardar el RelationShip en Database:', e);
            });
    }

    async updateRelationShipInChat() {
        const chatHistory: MessageModel = {
            message: await this.settingsService.changeRelationShipAvatar(),
            date: new Date(),
            role: 'system',
        };

        this.chatHistoryService.saveChatHistory(chatHistory);
    }

    async updateDataAvatar(data: any) {
        let user = await this.userDataService.checkUserDataLoaded();

        if (!user || !user.avatars[user.avatarSelected] || !data) return false;

        if (!this.authenticationService.isAuthenticated()) return null;
        await this.avatarApiService.updateAvatarApi(user.avatarSelected, data);
        await this.userDataService.checkAndUpdateUserData();

        return true;
    }
}
